import '@/helpers/domains'
import '@/plugins/echarts'

import SentryHelper from '@/helpers/SentryHelper'
import { i18n } from '@/i18n/setup'
import { initPubSub } from '@/pubsub/subscriber'
import App from '@admin/App.vue'
import { RouterAdmin } from '@admin/RouterAdmin'
import { StoreAdmin } from '@admin/StoreAdmin'
import AdminAccessControlRules from '@admin/accessControl/rules'
import { abilitiesPlugin } from '@casl/vue'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueTippy from 'vue-tippy'
import VueToasted from 'vue-toasted'
import Vuex from 'vuex'

initPubSub()

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(PortalVue) // TODO: с vue3 заменить на встроенный teleport
Vue.use(VueModal)

Vue.use(VueTippy, {
  onShow(options) {
    return Boolean(options.props.content)
  },
})

Vue.use(VueToasted, {
  position: 'top-right',
  duration: 2000,
})

Vue.use(VCalendar)
Vue.use(abilitiesPlugin, AdminAccessControlRules.ability)

const createApp = async function () {
  const router = RouterAdmin.init()
  const store = StoreAdmin.init()

  SentryHelper.init(router)

  return new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  })
}

createApp().then((app) => {
  document.querySelector('#preload')?.remove()
  app.$mount('#app')
})
