import { composeExtraData } from '@admin/accessControl/helpers'
import {
  extraDataToRules,
  permissionToRules,
} from '@admin/accessControl/mappers'
import { userActions } from '@admin/accessControl/userActions'
import { Ability, AbilityBuilder } from '@casl/ability'
import { getActiveModule } from 'Helpers/accessControl/module'

export default new (class Rules {
  constructor() {
    this.abilityBuilder = new AbilityBuilder(Ability)

    /**
     * @public
     */
    this.ability = this.abilityBuilder.build({
      /**
       * @param {Object} object - (vue-router)
       * @returns {string}
       */
      detectSubjectType: (object) => object.name,
    })
  }

  /**
   * @public
   */
  reset() {
    this.ability.update([])
  }

  /**
   * Определение правил CASL на основе пермишенов и модулей для роутов
   * @param {Object} args
   * @param {Object[]} args.modules
   * @param {string[]} args.permissions
   * @returns {Array}
   * @public
   */
  defineFor({ modules, permissions }) {
    const utils = {
      can: this.abilityBuilder.can,
      cannot: this.abilityBuilder.cannot,
    }

    const extraData = composeExtraData({
      permissions,
    })

    // Супер-админ
    // TODO: перенести логику в mappers.js, не выдавать все доступные абилити
    //       супер-админу, а перечислить недостающие и запрещающие
    //       как и для всех остальных пермишенов
    if (permissions.includes('admin_panel')) {
      // Супер-админ может все и везде (убрать в будущем)
      this.abilityBuilder.can('manage', 'all')

      // Только для супер-админа:
      this.abilityBuilder.can(userActions.UPDATE_SUPPORT_VOTE_COUNT, 'all')
      this.abilityBuilder.can(
        userActions.UPDATE_SUPPORT_QUESTION_DEPARTMENTS,
        'all',
      )
      this.abilityBuilder.can(userActions.UPDATE_ACTIVE_SCHOOL, 'all')
      this.abilityBuilder.can(userActions.UPDATE_SCHOOL_MODULES, 'all')

      this.abilityBuilder.can(userActions.UPDATE_CLIENT_TEST_FLAG, 'all')
      this.abilityBuilder.can(userActions.UPDATE_STUDENT_TEST_FLAG, 'all')

      if (getActiveModule(modules, 'chat') === undefined) {
        this.abilityBuilder.cannot(userActions.ENABLE_CHAT, 'all')
        this.abilityBuilder.cannot(userActions.READ, 'admin-messages')
      }
    } else {
      // Главная страница по-умолчанию, доступна всем
      this.abilityBuilder.can(userActions.READ, 'admin-main')
      this.abilityBuilder.can(userActions.READ_MENU_ITEM, 'admin-main')
    }

    permissions.forEach((permission) => {
      permissionToRules(
        {
          activeModules: modules,
          extraData,
          permission,
        },
        utils,
      )
    })

    extraDataToRules(
      {
        activeModules: modules,
        extraData,
        permissions,
      },
      utils,
    )

    this.ability.update(this.abilityBuilder.rules)

    return this.abilityBuilder.rules
  }
})()
