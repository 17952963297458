const routes = [
  {
    path: '/certifications',
    name: 'admin-certifications',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-certifications" */ '@admin/pages/admin/certification/Certifications.vue'
      ),
  },
  {
    path: '/certification/create',
    name: 'admin-the-certification-create',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-certification" */ '@admin/pages/admin/certification/TheCertification.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/certification/:certificationId(\\d+)',
    name: 'admin-the-certification',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-certification" */ '@admin/pages/admin/certification/TheCertification.vue'
      ),
  },
  {
    path: '/certification/statement/:statementId(\\d+)',
    name: 'admin-the-certification-statement',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-certification-statement" */ '@admin/pages/admin/familyCertification/TheCertificationStatement.vue'
      ),
  },
]

export default routes
