import triggerSendings from '@admin/routes/admin/settings/triggerSendings'

const routes = [
  {
    path: '/settings/schools',
    name: 'admin-settings-schools',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-schools" */ '@admin/pages/admin/settings/Schools.vue'
      ),
  },
  {
    path: '/settings/school/create',
    name: 'admin-settings-the-school-create',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-school" */ '@admin/pages/admin/settings/TheSchool.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/school/:schoolId(\\d+)',
    name: 'admin-settings-the-school',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-school" */ '@admin/pages/admin/settings/TheSchool.vue'
      ),
  },
  {
    path: '/settings/school/:schoolId(\\d+)/legal-entity/create',
    name: 'admin-settings-the-legal-entity-create',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-legal-entity" */ '@admin/pages/admin/settings/TheLegalEntity.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/school/:schoolId(\\d+)/legal-entity/:legalEntityId(\\d+)',
    name: 'admin-settings-the-legal-entity',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-legal-entity" */ '@admin/pages/admin/settings/TheLegalEntity.vue'
      ),
  },
  {
    path: '/settings/school/:schoolId(\\d+)/income-diagnostics-setting',
    name: 'admin-income-diagnostics-setting',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics-setting" */ '@admin/pages/admin/incomeDiagnostics/TheIncomeDiagnosticsSetting.vue'
      ),
  },

  {
    path: '/settings/family-education/schools',
    name: 'admin-family-educations',
    meta: {
      accessControlGroup: 'settings-family-education',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-family-educations" */ '@admin/pages/admin/settings/FamilyEducations.vue'
      ),
  },
  {
    path: '/settings/family-education/school/:schoolId(\\d+)',
    name: 'admin-settings-the-family-education',
    meta: {
      accessControlGroup: 'settings-family-education',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-family-education" */ '@admin/pages/admin/settings/TheFamilyEducation.vue'
      ),
  },

  {
    path: '/settings/academic-years/schools',
    name: 'admin-settings-academic-years',
    meta: {
      accessControlGroup: 'settings-academic-year',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-academic-years" */ '@admin/pages/admin/settings/AcademicYears.vue'
      ),
  },
  {
    path: '/settings/academic-years/schools/:schoolId(\\d+)',
    name: 'admin-settings-the-academic-year',
    meta: {
      accessControlGroup: 'settings-academic-year',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-academic-year" */ '@admin/pages/admin/settings/TheAcademicYear.vue'
      ),
  },

  {
    path: '/settings/timetable/',
    name: 'admin-settings-timetables',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-timetables" */ '@admin/pages/admin/settings/Timetables.vue'
      ),
  },
  {
    path: '/settings/timetable/:timetableId(\\d+)',
    name: 'admin-settings-the-timetable',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-timetable" */ '@admin/pages/admin/settings/TheTimetable.vue'
      ),
  },
  {
    path: '/settings/timetable/create',
    name: 'admin-settings-the-timetable-create',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-timetable" */ '@admin/pages/admin/settings/TheTimetable.vue'
      ),
    props: {
      isCreate: true,
    },
  },

  {
    path: '/settings/holidays',
    name: 'admin-settings-holidays',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-holidays" */ '@admin/pages/admin/settings/Holidays.vue'
      ),
  },
  {
    path: '/settings/holiday/create',
    name: 'admin-settings-the-holiday-create',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-holiday" */ '@admin/pages/admin/settings/TheHoliday.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/holiday/:holidayId(\\d+)',
    name: 'admin-settings-the-holiday',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-holiday" */ '@admin/pages/admin/settings/TheHoliday.vue'
      ),
  },

  {
    path: '/settings/partners',
    name: 'admin-settings-partners',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-partners" */ '@admin/pages/admin/settings/Partners.vue'
      ),
  },
  {
    path: '/settings/partners/create',
    name: 'admin-settings-the-partner-create',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-partner" */ '@admin/pages/admin/settings/ThePartner.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/partners/:partnerId(\\d+)',
    name: 'admin-settings-the-partner',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-partner" */ '@admin/pages/admin/settings/ThePartner.vue'
      ),
  },

  {
    path: '/settings/roles',
    name: 'admin-settings-roles',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-roles" */ '@admin/pages/admin/settings/Roles.vue'
      ),
  },
  {
    path: '/settings/roles/create',
    name: 'admin-settings-the-role-create',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-role" */ '@admin/pages/admin/settings/TheRole.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/roles/:roleName',
    name: 'admin-settings-the-role',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-role" */ '@admin/pages/admin/settings/TheRole.vue'
      ),
  },

  {
    path: '/settings/users',
    name: 'admin-settings-users',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-users" */ '@admin/pages/admin/settings/Users.vue'
      ),
  },
  {
    path: '/settings/users/create',
    name: 'admin-settings-the-user-create',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-user" */ '@admin/pages/admin/settings/TheUser.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/users/:userId(\\d+)',
    name: 'admin-settings-the-user',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-user" */ '@admin/pages/admin/settings/TheUser.vue'
      ),
  },

  {
    path: '/settings/wiki',
    name: 'admin-settings-wiki',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-wiki" */ '@admin/pages/admin/settings/Wiki.vue'
      ),
  },
  {
    path: '/settings/wiki/create',
    name: 'admin-settings-the-wiki-create',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-wiki" */ '@admin/pages/admin/settings/TheWiki.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/wiki/:wikiId(\\d+)',
    name: 'admin-settings-the-wiki',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-wiki" */ '@admin/pages/admin/settings/TheWiki.vue'
      ),
  },

  ...triggerSendings,
]

export default routes
