export default [
  {
    path: '/payments/promocode-groups',
    name: 'admin-payment-promocode-groups',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-promocode-groups" */ '@admin/pages/admin/payment/PromocodeGroups.vue'
      ),
  },
  {
    path: '/payments/promocode-group/create',
    name: 'admin-payment-the-promocode-group-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-promocode-group" */ '@admin/pages/admin/payment/ThePromocodeGroup.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/promocode-group/:promocodeGroupId(\\d+)',
    name: 'admin-payment-the-promocode-group',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-promocode-group" */ '@admin/pages/admin/payment/ThePromocodeGroup.vue'
      ),
  },
]
