<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DragAndDropSortContainer',

  mixins: [
    ContainerMixin,
  ],
})
</script>
