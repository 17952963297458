<template>
  <div class="d-flex align-items-center mt-2 mb-4">
    <div class="w-100 d-flex flex-column">
      <UiKitFormField class="mb-4" :label="t('name.label')" required>
        <UiKitInput v-model="name" :placeholder="t('name.placeholder')" />

        <UiKitFormFieldErrors :errors="v$.programBlock.name.$errors" />
      </UiKitFormField>

      <UiKitFormField :label="t('description.label')" required>
        <UiKitWysiwyg
          v-model="description"
          :placeholder="t('description.placeholder')"
        />

        <UiKitFormFieldErrors :errors="v$.programBlock.description.$errors" />
      </UiKitFormField>
    </div>
  </div>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitInput from '@admin/ui/admin/UiKitInput.vue'
import UiKitWysiwyg from '@admin/ui/admin/UiKitWysiwyg.vue'
import { defineComponent } from 'vue'
import { required } from 'Helpers/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import UiKitFormFieldErrors from '@admin/ui/admin/UiKitFormFieldErrors.vue'

export default defineComponent({
  name: 'FormEditProgram',

  components: {
    UiKitFormFieldErrors,
    UiKitFormField,
    UiKitInput,
    UiKitWysiwyg,
  },

  model: {
    prop: 'programBlock',
    event: 'input',
  },

  props: {
    programBlock: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      programBlock: {
        name: {
          required,
        },

        description: {
          required,
        },
      },
    }
  },

  computed: {
    name: {
      get() {
        return this.programBlock.name
      },

      set(value) {
        this.$emit('input', {
          key: 'name',
          value,
        })
      },
    },

    description: {
      get() {
        return this.programBlock.description
      },

      set(value) {
        this.$emit('input', {
          key: 'description',
          value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.program.form.${name}`,
      )
    },
  },
})
</script>
