import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setFamilyCertificationSchools(state, payload) {
    state.familyCertificationSchools = payload
  },

  setFamilyCertificationsSchedule(state, payload) {
    state.certificationsSchedule = payload
  },

  setFamilyCertificationSchedule(state, payload) {
    state.certificationSchedule = payload
  },

  setFamilyCertificationScheduleValue(state, { key, value }) {
    Vue.set(state.certificationSchedule, key, value)
  },
}
