<template>
  <Uploader
    :image="localImage"
    @error="emitError($event)"
    @upload-image="processUpload($event)"
  >
    <template #default="{ data, actions, events }">
      <div
        :class="{ 'uiKit-upload-small-image-hover': data.dragover }"
        class="uiKit-upload-small-image"
        @drop="actions.processUploadImage($event)"
        v-on="events.drag"
      >
        <div
          v-show="!data.isImageUploaded"
          class="uiKit-upload-small-image-dropzone"
        >
          <div class="uiKit-upload-small-image-preview">
            <span class="fas fa-file-image"></span>
          </div>

          <div
            v-show="!data.progress"
            class="uiKit-upload-small-image-filename"
          >
            {{ $t('ui.select_file') }}
          </div>

          <div
            v-show="data.progress"
            class="progress w-100 h-100 rounded-0 bg-transparent"
          >
            <div
              :style="{
                width: `${data.progress}%`,
              }"
              class="progress-bar bg-secondary"
            >
              {{ data.progress }}%
            </div>
          </div>

          <label
            @mouseleave="actions.drag().leave()"
            @mouseover="actions.drag().over()"
          >
            <input
              ref="upload"
              :disabled="readonly"
              type="file"
              @change="actions.processUploadImage($event)"
            />
          </label>
        </div>

        <div
          v-show="data.isImageUploaded"
          class="uiKit-upload-small-image-contents h-100"
          @mouseleave="actions.drag().leave()"
          @mouseover="actions.drag().over()"
        >
          <div class="uiKit-upload-small-image-preview">
            <VuePreviewer
              v-if="preview"
              :images="[
                {
                  src: data.imageUrl,
                  thumbnailSrc: data.imageThumbUrl,
                  width: '100%',
                  height: 'auto',
                },
              ]"
              :show-footer="false"
            />

            <img
              v-else
              :src="data.imageThumbUrl || data.imageUrl"
              alt="image"
            />
          </div>

          <!-- TODO: i18n -->
          <a
            v-show="!data.progress"
            class="uiKit-upload-small-image-filename"
            href="#"
            @click.prevent="actions.processChange($refs.upload)"
          >
            {{ data.imageName || 'Без названия' }}
          </a>

          <a
            v-if="!readonly && canClear"
            v-show="!data.progress"
            class="uiKit-upload-small-image-clear"
            href="#"
            @click.prevent="actions.processClearImage()"
          >
            <span class="fas fa-times"></span>
          </a>

          <div
            v-show="data.progress"
            class="progress w-100 h-100 rounded-0 bg-transparent"
          >
            <div
              :style="{
                width: `${data.progress}%`,
              }"
              class="progress-bar bg-secondary"
            >
              {{ data.progress }}%
            </div>
          </div>
        </div>
      </div>
    </template>
  </Uploader>
</template>

<script>
import Uploader from 'CommonComponents/Uploader.vue'
import { cloneDeep } from 'lodash'
import VuePreviewer from 'vue-image-previewer'

export default {
  name: 'UiKitUploadSmallImage',

  components: {
    VuePreviewer,
    Uploader,
  },

  model: {
    prop: 'image',
    event: 'upload',
  },

  props: {
    image: {
      type: Object,
      default: null,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    canClear: {
      type: Boolean,
      default: true,
    },

    preview: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localImage: null,
    }
  },

  watch: {
    image: {
      handler() {
        this.localImage = cloneDeep(this.image)
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     * @param {Object} e
     * @returns {boolean}
     */
    processUpload(e) {
      if (this.readonly) {
        return false
      }

      this.localImage = e
      this.$emit('upload', cloneDeep(this.localImage))

      return true
    },

    /**
     * @param {Event} e
     */
    emitError(e) {
      this.$emit('error', e)
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';
@import '~@admin/scss/bootstrap/variables';

$component-selector: '.uiKit-upload-small-image';

#{$component-selector} {
  $spacing: 18px;

  position: relative;
  width: 100%;
  height: $inputHeight;
  color: var(--uiKit-upload-small-image-color);
  background: var(--uiKit-upload-small-image-background);
  border-radius: $inputBorderRadius;

  &-hover {
    background-color: var(--uiKit-upload-small-image-backgroundActive);
  }

  &-dropzone {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;

    > label {
      position: absolute;
      inset: 0;
      display: block;
      height: 100%;
      padding: 0;
      margin: 0;
      cursor: pointer;

      > input {
        width: 100%;
        height: 0;
        visibility: hidden;
      }
    }
  }

  &-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-preview {
    position: relative;
    width: $inputHeight;
    min-width: $inputHeight;
    height: $inputHeight;
    margin: 0 $spacing 0 0;
    overflow: hidden;
    font-size: $inputHeight - $spacing;
    line-height: $inputHeight;
    text-align: center;
    border-radius: $inputBorderRadius;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > .vue-preview {
      width: 100%;
      height: 100%;

      .list {
        width: 100%;
        height: 100%;
      }

      .list__img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;

        img {
          position: absolute;
          left: 50%;
          width: auto;
          height: 100%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &-filename {
    display: block;
    width: 100%;
    height: $inputHeight;
    margin: 0 $spacing 0 0;
    overflow: hidden;
    font-size: 90%;
    line-height: $inputHeight;
    text-overflow: ellipsis;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  &-clear {
    display: block;
    width: $inputHeight;
    margin-right: math.div($spacing, 2);
    font-size: $inputHeight - $spacing;
    line-height: $inputHeight;
    text-align: center;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
