import { ApiCourseManagementProductCertificateMassIssue } from 'ApiRest/Api/CourseManagement/Products/Certificate/Massissue'
import { ApiCourseManagementProductCertificateRevoke } from 'ApiRest/Api/CourseManagement/Products/Certificate/Revoke'
import { ApiCourseManagementProductCertificateUserList } from 'ApiRest/Api/CourseManagement/Products/Certificate/UserList'
import { ApiCourseManagementProductCertificateIssue } from 'ApiRest/Api/CourseManagement/Products/Certificate/issue'
import { ApiCtpIsGroupLinked } from 'ApiRest/Api/Ctp/IsGroupLinked'
import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import { ApiOnlineSchoolProductList } from 'ApiRest/Api/OnlineSchool/Products/List'
import { ApiOnlineSchoolLiveEventApplication } from 'ApiRest/Api/OnlineSchool/Products/LiveEventApplication'
import { ApiOnlineSchoolProductAgreements } from 'ApiRest/Api/OnlineSchool/Products/Options/Agreements'
import { ApiOnlineSchoolProductCategories } from 'ApiRest/Api/OnlineSchool/Products/Options/Categories'
import { ApiOnlineSchoolProductOptionsCtps } from 'ApiRest/Api/OnlineSchool/Products/Options/Ctps'
import { ApiOnlineSchoolRelationshipTypes } from 'ApiRest/Api/OnlineSchool/Products/Options/CustomerRelationshipTypes'
import { ApiOnlineSchoolProductOptionsGroups } from 'ApiRest/Api/OnlineSchool/Products/Options/Groups'
import { ApiOnlineSchoolProductLeaders } from 'ApiRest/Api/OnlineSchool/Products/Options/Leaders'
import { ApiOnlineSchoolProductMaterialTypes } from 'ApiRest/Api/OnlineSchool/Products/Options/MaterialTypes'
import { ApiOnlineSchoolProductOptionsOnlineLessons } from 'ApiRest/Api/OnlineSchool/Products/Options/OnlineLessons'
import { ApiOnlineSchoolOptionSubscriptions } from 'ApiRest/Api/OnlineSchool/Products/Options/Subscriptions'
import { ApiOnlineSchoolProductTypes } from 'ApiRest/Api/OnlineSchool/Products/Options/Types'
import { ApiOnlineSchoolProduct } from 'ApiRest/Api/OnlineSchool/Products/Product'
import ApiOption from 'ApiRest/Api/Option'
import { ApiProductManagementOptionsProducts } from 'ApiRest/Api/ProductManagement/Options/Product'
import { ApiProductManagementFetchLinkAccess } from 'ApiRest/Api/Products/LinkAccess/Create'
import { ApiProductManagementLinkAccessSaveSettings } from 'ApiRest/Api/Products/LinkAccess/SaveSettings'
import { ApiProductManagementParametersList } from 'ApiRest/Api/Products/Parameters/ParametersList'
import { ApiProductGroup } from 'ApiRest/Api/Products/ProductGroup'
import { ApiProductManagementProducPromocodeTypes } from 'ApiRest/Api/Products/ProductPromocodeTypes'
import DownloadHelper from 'Helpers/DownloadHelper'

const commonParameters = {
  expand:
    'images,type,mainCategory,extraCategories,holding,tags,service,documents,materials,template,leaders,visibility,offers,users,agreements,documentTemplates,certificateTextOverlaySettings,certificateTemplate',
}

export default {
  async fetchProductList({ commit }, params) {
    const expandParams = {
      expand: 'extraCategories, holding, mainCategory, type, visibility',
    }

    const { data, headers } = await ApiOnlineSchoolProductList.get({
      ...params,
      ...expandParams,
    })

    commit('setFilters', params)
    commit('setProductList', data)
    commit('setPagination', headers)
  },

  async fetchSchoolOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schoolOptionList',
      value: data,
    })
  },

  async fetchTypeOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductTypes.get()

    commit('setOptionsList', {
      path: 'kindOptionList',
      value: data,
    })
  },

  async fetchGradeOptions({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptionsList', {
      path: 'gradeOptionList',
      value: data,
    })
  },

  async fetchMaterialTypeOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductMaterialTypes.get()

    commit('setOptionsList', {
      path: 'materialTypeOptionList',
      value: data,
    })
  },

  async fetchCategoryOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductCategories.get()

    commit('setOptionsList', {
      path: 'categoryOptionList',
      value: data,
    })
  },

  async fetchLeaderOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductLeaders.get()

    commit('setOptionsList', {
      path: 'leaderOptionList',
      value: data,
    })
  },

  async fetchParameterOptions({ commit }) {
    const { data } = await ApiProductManagementParametersList.get()

    commit('setOptionsList', {
      path: 'parameterOptionList',
      value: data,
    })
  },

  async fetchCtpOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getCtpsLite(params)

    commit('setOptionsList', {
      path: 'ctpList',
      value: data,
    })
  },

  async fetchOnlineLessonOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getOnlineLessonsLite(params)

    commit('setOptionsList', {
      path: 'onlineLessonList',
      value: data,
    })
  },

  async fetchCustomerRelationshipTypeOptions({ commit }) {
    const { data } = await ApiOnlineSchoolRelationshipTypes.get()

    commit('setOptionsList', {
      path: 'customerRelationshipTypeList',
      value: data,
    })
  },

  async fetchAgreementOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductAgreements.get()

    commit('setOptionsList', {
      path: 'agreementOptionList',
      value: data,
    })
  },

  async fetchStatusOptions({ commit }) {
    const { data } = await ApiOption.getProductStatuses()

    commit('setOptionsList', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchPromocodeOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiProductManagementProducPromocodeTypes.get(params)

    commit('setOptionsList', {
      path: 'promocodeList',
      value: data,
    })
  },

  async fetchStreamOptions({ commit }, payload) {
    const { data } = await ApiOption.getStreams(payload)

    commit('setOptionsList', {
      path: 'streamOptionList',
      value: data,
    })
  },

  async fetchEducationTypeOptions({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOptionsList', {
      path: 'educationTypeOptionList',
      value: data,
    })
  },

  async fetchCtpsOptions({ commit }, productId) {
    const { data } = await ApiOnlineSchoolProductOptionsCtps.get(productId)

    commit('setOptionsList', {
      path: 'ctpProductList',
      value: data,
    })
  },

  async fetchOnlineLessonsOptions({ commit }, productId) {
    const { data } =
      await ApiOnlineSchoolProductOptionsOnlineLessons.get(productId)

    commit('setOptionsList', {
      path: 'onlineLessonProductList',
      value: data,
    })
  },

  async fetchOptionSubscriptions({ commit }) {
    const { data } = await ApiOnlineSchoolOptionSubscriptions.get()

    commit('setOptionsList', {
      path: 'subscriptionList',
      value: data,
    })
  },

  async fetchGroupOptions({ commit }) {
    const { data } = await ApiOnlineSchoolProductOptionsGroups.get()

    commit('setOptionsList', {
      path: 'groupList',
      value: data,
    })
  },

  async fetchGroup(context, groupId) {
    const { data } = await ApiProductGroup.get(groupId)

    return data
  },

  async fetchProductOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchCategoryOptions'),
      dispatch('fetchGradeOptions'),
      dispatch('fetchLeaderOptions'),
      dispatch('fetchParameterOptions'),
      dispatch('fetchSchoolOptions'),
      dispatch('fetchTypeOptions'),
      dispatch('fetchMaterialTypeOptions'),
      dispatch('fetchCustomerRelationshipTypeOptions'),
      dispatch('fetchAgreementOptions'),
      dispatch('fetchStatusOptions'),
      dispatch('fetchEducationTypeOptions'),
      dispatch('fetchOptionSubscriptions'),
      dispatch('fetchGroupOptions'),
      dispatch('fetchProductsOptions'),
    ])
  },

  async createProduct({ commit, state }) {
    commit('cleanupOffers')

    const { data } = await ApiOnlineSchoolProduct.post(
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchProduct({ commit }, productId) {
    const { data } = await ApiOnlineSchoolProduct.get(
      productId,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async updateProduct({ commit, state }, productId) {
    commit('cleanupOffers')

    const { data } = await ApiOnlineSchoolProduct.put(
      productId,
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchEducationTypeVisibilitySchoolOptions(
    { commit },
    { index, params },
  ) {
    const { data } = await ApiOption.getEducationTypes(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'educationTypes',
      value: data,
    })
  },

  async fetchGradeVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getGrades(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'grades',
      value: data,
    })
  },

  async fetchStreamVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getStreamsLite(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'streams',
      value: data,
    })
  },

  async fetchVisibilitySchoolOptions({ dispatch }, { index, params }) {
    const payload = {
      index,
      params,
    }

    await Promise.all([
      dispatch('fetchEducationTypeVisibilitySchoolOptions', payload),
      dispatch('fetchGradeVisibilitySchoolOptions', payload),
      dispatch('fetchStreamVisibilitySchoolOptions', payload),
    ])
  },

  fetchSpecialOfferSchoolOptions() {
    return ApiOption.getSchools()
  },

  fetchSpecialOfferGradeOptions(context, params) {
    return ApiOption.getGrades(params)
  },

  fetchSpecialOfferEducationTypeOptions(context, params) {
    return ApiOption.getEducationTypes(params)
  },

  async fetchCertificateUserList({ commit }, productId) {
    const { data } = await ApiCourseManagementProductCertificateUserList.get({
      productId,
    })

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async issueCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateIssue.put(payload)

    commit('setCertificateUser', data)

    return data
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Array>}
   */
  async massIssueCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateMassIssue.put(payload)

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async revokeCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateRevoke.delete(payload)

    commit('setCertificateUser', data)

    return data
  },

  canAddCtp(context, ctpId) {
    return ApiCtpIsGroupLinked.get(ctpId)
  },

  async fetchGroupOnlineLessons(context, lessonId) {
    const { data } = await ApiOnlineLessonOption.getGroupOnlineLessons(lessonId)

    return data
  },

  saveLinkAccessSetting(context, payload) {
    return ApiProductManagementLinkAccessSaveSettings.post(payload.id, payload)
  },

  async createLinkCode({ commit }, productId) {
    const { data } = await ApiProductManagementFetchLinkAccess.get(productId)

    commit('setAccessCode', data)

    return data
  },

  async fetchLiveEventApplication({ state }) {
    const response = await ApiOnlineSchoolLiveEventApplication.get(
      state.product.id,
    )

    DownloadHelper.downloadBlob(response)
  },

  async fetchProductsOptions({ commit }, payload) {
    const { data } = await ApiProductManagementOptionsProducts.get(payload)

    commit('setOptionsList', {
      path: 'productList',
      value: data,
    })
  },
}
