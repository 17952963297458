const routes = [
  {
    path: '/messages',
    name: 'admin-messages',
    component: () =>
      import(
        /* webpackChunkName: "admin-main" */ '@admin/pages/admin/Messages.vue'
      ),
  },
]

export default routes
