import { ApiCourseManagementProductCertificateMassIssue } from 'ApiRest/Api/CourseManagement/Products/Certificate/Massissue'
import { ApiCourseManagementProductCertificateRevoke } from 'ApiRest/Api/CourseManagement/Products/Certificate/Revoke'
import { ApiCourseManagementProductCertificateUserList } from 'ApiRest/Api/CourseManagement/Products/Certificate/UserList'
import { ApiCourseManagementProductCertificateIssue } from 'ApiRest/Api/CourseManagement/Products/Certificate/issue'
import { ApiCourseManagementProductAgreements } from 'ApiRest/Api/CourseManagement/Products/Option/ProductAgreements'
import { ApiCourseManagementProductCategories } from 'ApiRest/Api/CourseManagement/Products/Option/ProductCategories'
import { ApiCourseManagementProductOptionsCtps } from 'ApiRest/Api/CourseManagement/Products/Option/ProductCtps'
import { ApiCourseManagementProductCustomerRelationshipTypes } from 'ApiRest/Api/CourseManagement/Products/Option/ProductCustomerRelationshipTypes'
import { ApiCourseManagementProductGroupsOptions } from 'ApiRest/Api/CourseManagement/Products/Option/ProductGroups'
import { ApiCourseManagementProductLeaders } from 'ApiRest/Api/CourseManagement/Products/Option/ProductLeaders'
import { ApiCourseManagementProductMaterialTypes } from 'ApiRest/Api/CourseManagement/Products/Option/ProductMaterialTypes'
import { ApiCourseManagementProductOptionsOnlineLessons } from 'ApiRest/Api/CourseManagement/Products/Option/ProductOnlineLessons'
import { ApiCourseManagementProductOptionsSubscriptions } from 'ApiRest/Api/CourseManagement/Products/Option/ProductSubscriptions'
import { ApiCourseManagementProductTypes } from 'ApiRest/Api/CourseManagement/Products/Option/ProductTypes'
import { ApiCourseManagementParametersList } from 'ApiRest/Api/CourseManagement/Products/Parameters/ParametersList'
import { ApiCourseManagementProduct } from 'ApiRest/Api/CourseManagement/Products/Product'
import { ApiCoursesLiveEventApplication } from 'ApiRest/Api/Courses/LiveEventApplication'
import { ApiCtpIsGroupLinked } from 'ApiRest/Api/Ctp/IsGroupLinked'
import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import ApiOption from 'ApiRest/Api/Option'
import { ApiProductGroup } from 'ApiRest/Api/Products/ProductGroup'
import { ApiProductManagementProducPromocodeTypes } from 'ApiRest/Api/Products/ProductPromocodeTypes'
import DownloadHelper from 'Helpers/DownloadHelper'

const commonParameters = {
  expand:
    'images,type,mainCategory,extraCategories,holding,tags,service,documents,materials,template,leaders,visibility,offers,users,agreements,documentTemplates,certificateTextOverlaySettings,certificateTemplate',
}

export default {
  async fetchGradeOptions({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptionsList', {
      path: 'gradeOptionList',
      value: data,
    })
  },

  async fetchInvoiceServiceOptions({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setOptionsList', {
      path: 'serviceOptionList',
      value: data,
    })
  },

  async fetchSchoolOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schoolOptionList',
      value: data,
    })
  },

  async fetchTypeOptions({ commit }) {
    const { data } = await ApiCourseManagementProductTypes.get()

    commit('setOptionsList', {
      path: 'kindOptionList',
      value: data,
    })
  },

  async fetchMaterialTypeOptions({ commit }) {
    const { data } = await ApiCourseManagementProductMaterialTypes.get()

    commit('setOptionsList', {
      path: 'materialTypeOptionList',
      value: data,
    })
  },

  async fetchCategoryOptions({ commit }) {
    const { data } = await ApiCourseManagementProductCategories.get()

    commit('setOptionsList', {
      path: 'categoryOptionList',
      value: data,
    })
  },

  async fetchLeaderOptions({ commit }) {
    const { data } = await ApiCourseManagementProductLeaders.get()

    commit('setOptionsList', {
      path: 'leaderOptionList',
      value: data,
    })
  },

  async fetchParameterOptions({ commit }) {
    const { data } = await ApiCourseManagementParametersList.get()

    commit('setOptionsList', {
      path: 'parameterOptionList',
      value: data,
    })
  },

  async fetchCtpOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getCtpsLite(params)

    commit('setOptionsList', {
      path: 'ctpList',
      value: data,
    })
  },

  async fetchOnlineLessonOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiOption.getOnlineLessonsLite(params)

    commit('setOptionsList', {
      path: 'onlineLessonList',
      value: data,
    })
  },

  async fetchCustomerRelationshipTypeOptions({ commit }) {
    const { data } =
      await ApiCourseManagementProductCustomerRelationshipTypes.get()

    commit('setOptionsList', {
      path: 'customerRelationshipTypeList',
      value: data,
    })
  },

  async fetchAgreementOptions({ commit }) {
    const { data } = await ApiCourseManagementProductAgreements.get()

    commit('setOptionsList', {
      path: 'agreementOptionList',
      value: data,
    })
  },

  async fetchStatusOptions({ commit }) {
    const { data } = await ApiOption.getProductStatuses()

    commit('setOptionsList', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchPromocodeOptions({ commit }, name) {
    const params = {
      name,
    }

    const { data } = await ApiProductManagementProducPromocodeTypes.get(params)

    commit('setOptionsList', {
      path: 'promocodeList',
      value: data,
    })
  },

  async fetchStreamOptions({ commit }, payload) {
    const { data } = await ApiOption.getStreams(payload)

    commit('setOptionsList', {
      path: 'streamOptionList',
      value: data,
    })
  },

  async fetchEducationTypeOptions({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOptionsList', {
      path: 'educationTypeOptionList',
      value: data,
    })
  },

  async fetchCtpsOptions({ commit }, productId) {
    const { data } = await ApiCourseManagementProductOptionsCtps.get(productId)

    commit('setOptionsList', {
      path: 'ctpProductList',
      value: data,
    })
  },

  async fetchOnlineLessonsOptions({ commit }, productId) {
    const { data } =
      await ApiCourseManagementProductOptionsOnlineLessons.get(productId)

    commit('setOptionsList', {
      path: 'onlineLessonProductList',
      value: data,
    })
  },

  async fetchOptionSubscriptions({ commit }) {
    const { data } = await ApiCourseManagementProductOptionsSubscriptions.get()

    commit('setOptionsList', {
      path: 'subscriptionList',
      value: data,
    })
  },

  async fetchGroupOptions({ commit }) {
    const { data } = await ApiCourseManagementProductGroupsOptions.get()

    commit('setOptionsList', {
      path: 'groupList',
      value: data,
    })
  },

  async fetchGroup(context, groupId) {
    const { data } = await ApiProductGroup.get(groupId)

    return data
  },

  async fetchProductOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchCategoryOptions'),
      dispatch('fetchGradeOptions'),
      dispatch('fetchInvoiceServiceOptions'),
      dispatch('fetchLeaderOptions'),
      dispatch('fetchParameterOptions'),
      dispatch('fetchSchoolOptions'),
      dispatch('fetchTypeOptions'),
      dispatch('fetchMaterialTypeOptions'),
      dispatch('fetchCustomerRelationshipTypeOptions'),
      dispatch('fetchAgreementOptions'),
      dispatch('fetchStatusOptions'),
      dispatch('fetchEducationTypeOptions'),
      dispatch('fetchOptionSubscriptions'),
      dispatch('fetchGroupOptions'),
    ])
  },

  async createProduct({ commit, state }) {
    commit('cleanupOffers')

    const { data } = await ApiCourseManagementProduct.post(
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchProduct({ commit }, productId) {
    const { data } = await ApiCourseManagementProduct.get(
      productId,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async updateProduct({ commit, state }, productId) {
    commit('cleanupOffers')

    const { data } = await ApiCourseManagementProduct.put(
      productId,
      state.product,
      commonParameters,
    )

    commit('setProduct', data)
  },

  async fetchEducationTypeVisibilitySchoolOptions(
    { commit },
    { index, params },
  ) {
    const { data } = await ApiOption.getEducationTypes(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'educationTypes',
      value: data,
    })
  },

  async fetchGradeVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getGrades(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'grades',
      value: data,
    })
  },

  async fetchStreamVisibilitySchoolOptions({ commit }, { index, params }) {
    const { data } = await ApiOption.getStreamsLite(params)

    commit('setVisibilitySchoolsOptionsList', {
      index,
      option: 'streams',
      value: data,
    })
  },

  async fetchVisibilitySchoolOptions({ dispatch }, { index, params }) {
    const payload = {
      index,
      params,
    }

    await Promise.all([
      dispatch('fetchEducationTypeVisibilitySchoolOptions', payload),
      dispatch('fetchGradeVisibilitySchoolOptions', payload),
      dispatch('fetchStreamVisibilitySchoolOptions', payload),
    ])
  },

  fetchSpecialOfferSchoolOptions() {
    return ApiOption.getSchools()
  },

  fetchSpecialOfferGradeOptions(context, params) {
    return ApiOption.getGrades(params)
  },

  fetchSpecialOfferEducationTypeOptions(context, params) {
    return ApiOption.getEducationTypes(params)
  },

  async fetchCertificateUserList({ commit }, productId) {
    const { data } = await ApiCourseManagementProductCertificateUserList.get({
      productId,
    })

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async issueCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateIssue.put(payload)

    commit('setCertificateUser', data)

    return data
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Array>}
   */
  async massIssueCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateMassIssue.put(payload)

    commit('setCertificateUserList', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  async revokeCertificate({ commit }, payload) {
    const { data } =
      await ApiCourseManagementProductCertificateRevoke.delete(payload)

    commit('setCertificateUser', data)

    return data
  },

  canAddCtp(context, ctpId) {
    return ApiCtpIsGroupLinked.get(ctpId)
  },

  async fetchGroupOnlineLessons(context, lessonId) {
    const { data } = await ApiOnlineLessonOption.getGroupOnlineLessons(lessonId)

    return data
  },

  async fetchLiveEventApplication({ state }) {
    const response = await ApiCoursesLiveEventApplication.get(state.product.id)

    DownloadHelper.downloadBlob(response)
  },
}
