<template>
  <div>
    <UiKitFormField :label="t('label')" class="mb-4">
      <UiKitSelect
        v-model="leaders"
        :hide-selected="true"
        :multiple="true"
        :options="options.leaderOptionList"
      />
    </UiKitFormField>

    <UiKitButton class="btn-success mb-4" @click="onClickBtnAdd">
      <i class="fa fa-plus"></i>
      {{ t('btn_add') }}
    </UiKitButton>

    <DragAndDropSortContainer
      v-model="leaders"
      lock-axis="y"
      :use-drag-handle="true"
    >
      <LeadersItem
        v-for="(leader, index) in leaders"
        :key="index"
        :index="index"
        :leader="leader"
        @edit="() => onEdit(leader, index)"
        @remove="() => onRemove(index)"
      />
      <UiKitFormFieldErrors :errors="v$.localItem.leaders.$errors" />
    </DragAndDropSortContainer>

    <ModalAddLeader ref="modalAddLeader" @add="onAddLeader" />

    <ModalEditLeader
      ref="modalEditLeader"
      @change="onChangeLeader"
      @hide="onHideModalEditLeaderBlock"
    />
  </div>
</template>

<script>
import DragAndDropSortContainer from '@admin/components/admin/productManagement/product/content/DragAndDropSortContainer.vue'
import LeadersItem from '@admin/components/admin/productManagement/product/content/items/leaders/LeadersItem.vue'
import ModalAddLeader from '@admin/components/admin/productManagement/product/content/items/leaders/ModalAddLeader.vue'
import ModalEditLeader from '@admin/components/admin/productManagement/product/content/items/leaders/ModalEditLeader.vue'
import UiKitSelect from '@admin/ui/admin/UiKitSelect.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from 'Helpers/i18n-validators'
import UiKitFormFieldErrors from '@admin/ui/admin/UiKitFormFieldErrors.vue'

export default defineComponent({
  name: 'ProductContentItemLeaders',

  components: {
    UiKitFormFieldErrors,
    DragAndDropSortContainer,
    LeadersItem,
    ModalEditLeader,
    ModalAddLeader,
    UiKitSelect,
    UiKitButton,
    UiKitFormField,
  },

  inheritAttrs: false,

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      localItem: {
        leaders: {
          required,
        },
      },
    }
  },

  data() {
    return {
      leaderBlockIndex: null,
      localItem: null,
    }
  },

  computed: {
    ...mapState('admin/productManagement/product', [
      'options',
    ]),

    leaders: {
      get() {
        return this.localItem.leaders
      },

      set(value) {
        this.setLeaders(value)
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onClickBtnAdd() {
      this.$refs.modalAddLeader.show()
    },

    onEdit(leaderBlock, index) {
      this.leaderBlockIndex = index
      this.$refs.modalEditLeader.show(leaderBlock)
    },

    onRemove(index) {
      this.removeLeader(index)
      this.emitChange()
    },

    onAddLeader(leaderBlock) {
      this.addLeader(leaderBlock)
      this.emitChange()
    },

    onChangeLeader(leaderBlock) {
      this.updateLeader({
        index: this.leaderBlockIndex,
        value: leaderBlock,
      })
      this.emitChange()
    },

    onHideModalEditLeaderBlock() {
      this.leaderBlockIndex = null
    },

    addLeader(payload) {
      this.localItem.leaders.push(payload)
    },

    removeLeader(index) {
      this.localItem.leaders.splice(index, 1)
    },

    updateLeader({ index, value }) {
      this.$set(this.localItem.leaders, index, value)
    },

    setLeaders(value) {
      this.localItem.leaders = value
      this.emitChange()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.leaders.${name}`)
    },
  },
})
</script>
