import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportFaqTreeSort = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload, parameters) {
    const url = `/support/faq/sort?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }
})()
