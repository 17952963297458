import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheLibraryQuestion(state, payload) {
    state.question = payload
  },

  setTheLibraryQuestionValue(state, { key, value }) {
    Vue.set(state.question, key, value)
  },

  setTheLibraryQuestionContent(state, content) {
    Vue.set(state.question, 'content', content)
  },

  setTheLibraryQuestionMaterials(state, content) {
    Vue.set(state.question, 'materials', content)
  },
}
