import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiQuestionLogs = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(questionId, parameters) {
    const url = `/support/questions/${questionId}/logs?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }
})()
