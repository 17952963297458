<template>
  <LoaderFullScreen v-if="isLoading" />

  <div v-else :class="$style.page">
    <div :class="$style.container" class="py-3">
      <div :class="$style.sidebar" class="px-3">
        <UiKitNavigation :show-burger="true" />

        <UiKitButton
          class="btn-lg btn-primary mt-4 w-100"
          @click="onClickBtnLogout"
        >
          <span class="mr-2">
            {{ $t('layouts.admin_main.exit') }}
          </span>
          <i class="fa fa-sign-out-alt"></i>
        </UiKitButton>

        <portal-target name="left" />
      </div>

      <div :class="$style.content" class="px-3">
        <transition mode="out-in" name="fade">
          <main :class="$style.main">
            <router-view />
          </main>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { userActions } from '@admin/accessControl/userActions'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitNavigation from '@admin/ui/admin/UiKitNavigation.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import { authUrl } from 'Helpers/const/authUrl'
import { redirectToAuth } from 'Helpers/redirectHelper'
import { mapActions } from 'vuex'

export default {
  name: 'TheMain',

  components: {
    UiKitButton,
    LoaderFullScreen,
    UiKitNavigation,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    canEnableChat() {
      return this.$can(userActions.ENABLE_CHAT, this.$route.name)
    },
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    this.stopPollingMessages()
  },

  methods: {
    ...mapActions('admin/messages', [
      'startPollingMessages',
      'stopPollingMessages',
    ]),

    async init() {
      if (this.canEnableChat) {
        await this.startPollingMessages()
      }
    },

    onClickBtnLogout() {
      redirectToAuth(authUrl.logout, window.location.href)
    },
  },
}
</script>

<style lang="scss" module>
@import '~bootstrap/scss/mixins';
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;

  > * {
    height: 100%;
    overflow-x: hidden;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    overflow-y: scroll;

    > * {
      height: auto;
    }
  }
}

.sidebar {
  width: 325px;
  overflow-y: scroll;

  @include media-breakpoint-down(sm) {
    width: 100%;
    overflow-y: visible;
  }
}

.content {
  position: relative;
  flex: 1;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.main {
  position: relative;
  z-index: 0;
  height: 100%;

  > * {
    height: 100%;
  }
}
</style>
