const routes = [
  {
    path: '/full-time-statement',
    name: 'admin-the-full-time-statement',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-full-time-statement" */
        '@admin/pages/admin/fullTimeStatement/TheFullTimeStatement.vue'
      ),
  },
]

export default routes
