const routes = [
  {
    path: '/enrollment-contracts',
    name: 'admin-enrollment-contracts',
    meta: {
      accessControlGroup: 'enrollment-contract',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-enrollment-contracts" */
        '@admin/pages/admin/contract/Contracts.vue'
      ),
  },

  {
    path: '/enrollment-contract/detail/:enrollmentContractId(\\d+)',
    name: 'admin-the-enrollment-contract',
    meta: {
      accessControlGroup: 'enrollment-contract',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-enrollment-contract" */
        '@admin/pages/admin/contract/TheContract.vue'
      ),
  },
]

export default routes
