<template>
  <Uploader
    :file="localFile"
    :is-video="isVideo"
    :max-file-size-mb="maxFileSizeMb"
    @error="emitError"
    @upload-file="processUpload"
    @clear-file="processClear"
    @edit-image="processEdit"
    @view-image="processView"
  >
    <template #default="{ data, actions, events }">
      <div
        :class="cssClass(data)"
        class="uiKit-upload-small-any"
        @drop="(e) => actions.processUploadAny(e, isPrivate)"
        v-on="events.drag"
      >
        <div
          v-show="!data.isFileUploaded"
          class="uiKit-upload-small-any-dropzone"
        >
          <div class="uiKit-upload-small-any-preview">
            <span class="fa fa-upload"></span>
          </div>

          <div v-show="!data.progress" class="uiKit-upload-small-any-filename">
            {{ $t('ui.select_file') }}
          </div>

          <div
            v-show="data.progress"
            class="progress w-100 h-100 rounded-0 bg-transparent"
          >
            <div
              :style="{ width: `${data.progress}%` }"
              class="progress-bar bg-secondary"
            >
              {{ data.progress }}%
            </div>
          </div>

          <label
            @mouseleave="actions.drag().leave()"
            @mouseover="actions.drag().over()"
          >
            <input
              ref="upload"
              :accept="accept"
              :disabled="readonly"
              type="file"
              @change="(e) => actions.processUploadAny(e, isPrivate)"
            />
          </label>
        </div>

        <div
          v-if="file"
          v-show="data.isFileUploaded"
          class="uiKit-upload-small-any-contents"
          @mouseleave="actions.drag().leave()"
          @mouseover="actions.drag().over()"
        >
          <div class="uiKit-upload-small-any-preview">
            <template v-if="file.isImage && !file.mime.includes('heic')">
              <img v-if="file.thumbUrl" :src="file.thumbUrl" alt="thumbnail" />

              <span v-else class="fas fa-file-code"></span>
            </template>

            <template v-else>
              <!-- Например, .heic файлы  -->
              <span
                v-if="file.mime && file.mime.includes('image')"
                class="fas fa-file-image"
              ></span>

              <span
                v-else-if="
                  file.mime &&
                  (file.mime.includes('audio/mpeg') ||
                    file.mime.includes('audio/mp4'))
                "
                class="fas fa-file-video"
              ></span>

              <span
                v-else-if="file.mime && file.mime.includes('word')"
                class="fas fa-file-word"
              ></span>

              <span
                v-else-if="
                  file.mime &&
                  (file.mime.includes('excel') || file.mime.includes('sheet'))
                "
                class="fas fa-file-excel"
              ></span>

              <span
                v-else-if="file.mime && file.mime.includes('pdf')"
                class="fas fa-file-pdf"
              ></span>

              <span
                v-else-if="file.mime && file.mime.includes('text/plain')"
                class="fas fa-file-alt"
              ></span>

              <span v-else class="fas fa-file"></span>
            </template>
          </div>

          <a
            v-show="!data.progress"
            class="uiKit-upload-small-any-filename"
            href="#"
            @click.prevent="actions.processChange($refs.upload)"
          >
            {{ data.fileName || 'Без названия' }}
          </a>

          <a
            v-if="data.isImage"
            v-show="!data.progress"
            href="#"
            class="uiKit-upload-small-any-view"
            @click.prevent="processView"
          >
            <i class="fas fa-eye"></i>
          </a>

          <a
            v-if="data.isImage"
            v-show="!data.progress"
            href="#"
            class="uiKit-upload-small-any-edit"
            @click.prevent="processEdit"
          >
            <i class="fas fa-pencil-alt"></i>
          </a>

          <a
            v-if="data.downloadUrl && canDownload"
            v-show="!data.progress"
            :href="data.downloadUrl"
            class="uiKit-upload-small-any-download"
          >
            <i class="fas fa-download"></i>
          </a>

          <a
            v-if="!readonly && canClear"
            v-show="!data.progress"
            class="uiKit-upload-small-any-clear"
            href="#"
            @click.prevent="actions.processClearFile"
          >
            <i class="fas fa-times"></i>
          </a>

          <div
            v-show="data.progress"
            class="progress w-100 h-100 rounded-0 bg-transparent"
          >
            <div
              :style="{ width: `${data.progress}%` }"
              class="progress-bar bg-secondary"
            >
              {{ data.progress }}%
            </div>
          </div>
        </div>

        <ModalEditFileUpload
          ref="modalEditFileUpload"
          :file="localFile"
          :original-url="originalUrl"
          @upload-file="processUpload"
        />

        <ModalViewImage
          ref="modalViewImage"
          :file="localFile"
          :original-url="originalUrl"
        />
      </div>
    </template>
  </Uploader>
</template>

<script>
import ApiDownload from 'Api/Download'
import ModalEditFileUpload from 'CommonComponents/ModalEditFileUpload.vue'
import ModalViewImage from 'CommonComponents/ModalViewImage.vue'
import Uploader from 'CommonComponents/Uploader.vue'
import { cloneDeep } from 'lodash'

/**
 * @typedef {Object} UploadedFile
 * @property {number} id - Идентификатор
 * @property {string} name - Название
 * @property {string} mime - MIME
 * @property {string} url - URL
 * @property {string} thumbUrl - Thumb URL
 */

export default {
  name: 'UiKitUploadAny',

  components: {
    Uploader,
    ModalEditFileUpload,
    ModalViewImage,
  },

  model: {
    prop: 'file',
    event: 'upload',
  },

  props: {
    /**
     * v-model
     * @type {null|UploadedFile}
     */
    file: {
      type: Object,
      default: null,
    },

    accept: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    canClear: {
      type: Boolean,
      default: true,
    },

    canDownload: {
      type: Boolean,
      default: true,
    },

    isPrivate: {
      type: Boolean,
      default: false,
    },

    isVideo: {
      type: Boolean,
      default: false,
    },

    maxFileSizeMb: {
      type: Number,
      default: 50,
    },
  },

  data() {
    return {
      localFile: null,
      originalUrl: null,
    }
  },

  computed: {
    cssClass() {
      return (data) => [
        {
          'uiKit-upload-small-any-hover': data.dragover,
          'uiKit-upload-small-any-readonly': this.readonly,
        },
      ]
    },
  },

  watch: {
    // Sync local data with a prop

    file: {
      handler(value) {
        if (value) {
          this.localFile = cloneDeep(value)
          this.getOriginalUrl()
        } else {
          this.processClear()
        }
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     * @param {Event} e
     */
    processUpload(e) {
      if (!this.readonly) {
        this.localFile = e
        this.$emit('upload', cloneDeep(this.localFile))
      }
    },

    processClear() {
      if (!this.readonly) {
        this.localFile = null
        this.$emit('clear')
      }
    },

    processEdit() {
      this.showModalEdit()
    },

    processView() {
      this.showModalView()
    },

    /**
     * @param {Event} e
     */
    emitError(e) {
      this.$emit('error', e)
    },

    async getOriginalUrl() {
      if (this.localFile.isImage && !this.localFile.originalUrl) {
        this.originalUrl = await this.getBase64Image()
      }
    },

    showModalEdit() {
      const modal = this.$refs.modalEditFileUpload

      modal.show()
    },

    showModalView() {
      const modal = this.$refs.modalViewImage

      modal.show()
    },

    getDownloadUrl() {
      // Необходимо добавлять "хвост" для того,
      // чтобы браузер не кешировал изображения во время манипуляций с ними,
      // например, при повороте изображения.
      const hash = Date.now()

      if (this.customDownloadUrl) {
        return `${this.customDownloadUrl(this.file)}?${hash}`
      }

      return `${ApiDownload.getFileUrl(this.file.id)}?${hash}`
    },

    getBase64Image() {
      const image = new Image()

      image.crossOrigin = 'use-credentials'
      image.src = this.getDownloadUrl()

      return new Promise((resolve) => {
        image.addEventListener('load', () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')

          canvas.height = this.file.height
          canvas.width = this.file.width
          context.drawImage(image, 0, 0)
          const dataURL = canvas.toDataURL(this.file.mime)

          resolve(dataURL)
        })
      })
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';
@import '~@admin/scss/bootstrap/variables';

.uiKit-upload-small-any {
  $spacing: 18px;

  position: relative;
  width: 100%;
  height: $inputHeight;
  color: var(--uiKit-upload-small-audio-color);
  background: var(--uiKit-upload-small-audio-background);
  border-radius: $inputBorderRadius;

  &-hover {
    background-color: var(--uiKit-upload-small-audio-backgroundActive);
  }

  &-readonly {
    background-color: var(--uiKit-upload-small-audio-background);

    label {
      cursor: not-allowed !important;
    }
  }

  &-dropzone {
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: row;
    align-items: center;

    > label {
      position: absolute;
      inset: 0;
      display: block;
      height: 100%;
      padding: 0;
      margin: 0;
      cursor: pointer;

      > input {
        width: 100%;
        height: 0;
        visibility: hidden;
      }
    }
  }

  &-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-preview {
    position: relative;
    width: $inputHeight;
    min-width: $inputHeight;
    height: $inputHeight;
    margin: 0 $spacing 0 0;
    overflow: hidden;
    font-size: $inputHeight - $spacing;
    line-height: $inputHeight;
    text-align: center;
    border-radius: $inputBorderRadius;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-filename {
    display: block;
    width: 100%;
    height: $inputHeight;
    margin: 0 $spacing 0 0;
    overflow: hidden;
    font-size: 90%;
    line-height: $inputHeight;
    text-overflow: ellipsis;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  &-edit,
  &-view,
  &-download,
  &-clear {
    display: block;
    width: $inputHeight;
    margin-right: math.div($spacing, 2);
    line-height: $inputHeight;
    text-align: center;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  &-edit {
    font-size: $inputHeight - $spacing - 8px;
  }

  &-download {
    font-size: $inputHeight - $spacing - 6px;
  }

  &-clear {
    font-size: $inputHeight - $spacing;
  }
}
</style>
