<template>
  <div class="mb-4">
    <UiKitFormField :label="t('label')">
      <DragAndDropSortContainer
        v-model="partners"
        lock-axis="y"
        :use-drag-handle="true"
      >
        <PartnersItem
          v-for="(partnersItem, index) in partners"
          :key="index"
          :index="index"
          :item="partnersItem"
          @edit="() => onEdit(partnersItem, index)"
          @remove="() => onRemove(index)"
        />
      </DragAndDropSortContainer>
    </UiKitFormField>

    <UiKitButton class="btn-success" @click="onClickBtnAdd">
      <i class="fa fa-plus"></i>
      {{ t('btn_add') }}
    </UiKitButton>

    <UiKitFormFieldErrors :errors="v$.localItem.partners.$errors" />

    <ModalAddPartnersItem ref="modalAddPartnersItem" @add="onAddPartnersItem" />

    <ModalEditPartnersItem
      ref="modalEditPartnersItem"
      @change="onChangePartnersItem"
      @hide="onHideModalEditPartnersItem"
    />
  </div>
</template>

<script>
import DragAndDropSortContainer from '@admin/components/admin/productManagement/product/content/DragAndDropSortContainer.vue'
import PartnersItem from '@admin/components/admin/productManagement/product/content/items/partners/PartnersItem.vue'
import ModalAddPartnersItem from '@admin/components/admin/productManagement/product/content/items/partners/ModalAddPartnersItem.vue'
import ModalEditPartnersItem from '@admin/components/admin/productManagement/product/content/items/partners/ModalEditPartnersItem.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from 'Helpers/i18n-validators'
import UiKitFormFieldErrors from '@admin/ui/admin/UiKitFormFieldErrors.vue'

export default defineComponent({
  name: 'ProductContentItemPartners',

  components: {
    UiKitFormFieldErrors,
    DragAndDropSortContainer,
    PartnersItem,
    UiKitFormField,
    ModalEditPartnersItem,
    ModalAddPartnersItem,
    UiKitButton,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      localItem: {
        partners: {
          required,
        },
      },
    }
  },

  data() {
    return {
      partnersItemIndex: null,
      localItem: null,
    }
  },

  computed: {
    partners: {
      get() {
        return this.localItem.partners
      },

      set(value) {
        this.setPartners(value)
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onClickBtnAdd() {
      this.$refs.modalAddPartnersItem.show()
    },

    onEdit(partnersItem, index) {
      this.partnersItemIndex = index
      this.$refs.modalEditPartnersItem.show(partnersItem)
    },

    onRemove(index) {
      this.removePartnersItem(index)
      this.emitChange()
    },

    onAddPartnersItem(partnersItem) {
      this.addPartnersItem(partnersItem)
      this.emitChange()
    },

    onChangePartnersItem(partnersItem) {
      this.updatePartnersItem({
        index: this.partnersItemIndex,
        value: partnersItem,
      })
      this.emitChange()
    },

    onHideModalEditPartnersItem() {
      this.partnersItemIndex = null
    },

    removePartnersItem(index) {
      this.localItem.partners.splice(index, 1)
    },

    updatePartnersItem({ index, value }) {
      this.$set(this.localItem.partners, index, value)
    },

    addPartnersItem(partnersItem) {
      this.localItem.partners.push(partnersItem)
    },

    setPartners(value) {
      this.localItem.partners = value
      this.emitChange()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.partners.${name}`)
    },
  },
})
</script>
