import { userActions } from '@admin/accessControl/userActions'

/**
 * Разрешения по отдельным роутам без группы
 * @param {Object} data
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @returns {boolean}
 */
export const setAbilityByPermissionForRoutesWithoutGroup = ({
  helpers,
  permission,
}) => {
  // Администрирование: Лог ЭДУКОНТ
  // TODO: заменить на реальный пермишен
  if (permission === 'admin_educont_log') {
    helpers.allowReadRoutes([
      'admin-administrating-educont-log',
    ])

    return true
  }

  // Аналитика
  if (permission === 'analytics') {
    helpers.allowCrudRoutes([
      'admin-analytics',
    ])

    return true
  }

  // Аналитика по КТП
  if (permission === 'analytics_ctp') {
    const routeName = 'admin-analytics-ctp'

    helpers.allowReadRoutes([
      routeName,
    ])

    return true
  }

  // Аналитика по библиотеке
  if (permission === 'analytics_library') {
    const routeName = 'admin-analytics-library'

    helpers.allowReadRoutes([
      routeName,
    ])

    return true
  }

  // Аналитика: Отчет по зачисленным
  if (permission === 'analytic_student_enrolled_read') {
    helpers.allowReadRoutes([
      'admin-analytics-student-enrolled',
    ])

    return true
  }

  if (permission === 'analytic_student_enrolled_write') {
    helpers.allowModifyRoutes([
      'admin-analytics-student-enrolled',
    ])

    return true
  }

  // Авторизация под любым пользователем
  if (permission === 'auth_any') {
    helpers.allowCrudRoutes([
      'admin-auth-any',
    ])

    return true
  }

  // Ведомость ОО
  if (permission === 'fulltime_statement') {
    helpers.allowCrudRoutes([
      'admin-the-full-time-statement',
    ])

    return true
  }

  // Рейтинг
  if (permission === 'rating') {
    helpers.allowCrudRoutes([
      'admin-rating',
    ])

    return true
  }

  // Кредиты
  if (permission === 'credits') {
    helpers.allowCrudRoutes([
      'admin-credits',
    ])

    return true
  }

  // Ведомость и ведомость СО
  if (permission === 'statement') {
    const routeNames = [
      'admin-the-statement',
      'admin-family-certification-statement',
    ]

    helpers.allowCrudRoutes(routeNames)

    return true
  }

  if (permission === 'statement_manual_mark') {
    helpers.allowActionOnRoutes(userActions.UPDATE_STATEMENT_MANUAL_MARK, [
      'admin-the-statement',
      'admin-family-certification-statement',
      'admin-the-family-certification',
    ])

    return true
  }

  if (permission === 'statement_upload_scan') {
    helpers.allowActionOnRoutes(userActions.UPDATE_STATEMENT_UPLOAD, [
      'admin-the-statement',
    ])

    return true
  }

  // Потоки
  if (permission === 'streams') {
    helpers.allowActionOnRoutes(userActions.READ_STREAMS, [
      'admin-student-streams',
    ])

    return true
  }

  if (permission === 'ctp_educont_cover') {
    helpers.allowActionOnRoutes(userActions.UPDATE_CTP_COVER_FILE, [
      'admin-the-ctp-create',
      'admin-the-ctp',
    ])

    return true
  }

  if (permission === 'menu_courses_management_groups') {
    helpers.allowReadMenuItemForRoutes([
      'admin-course-management-groups',
    ])
    helpers.allowCrudRoutes([
      'admin-the-group',
      'admin-the-group-create',
    ])

    return true
  }

  if (permission === 'menu_product_management_groups') {
    helpers.allowReadMenuItemForRoutes([
      'admin-product-management-groups',
    ])
    helpers.allowCrudRoutes([
      'admin-the-group',
      'admin-the-group-create',
    ])

    return true
  }

  // Сообщения
  if (permission === 'messages') {
    const routeName = 'admin-messages'

    helpers.allowReadRoutes([
      routeName,
    ])

    return true
  }

  if (permission === 'sendings') {
    helpers.allowCrudRoutes([
      'admin-sendings',
      'admin-the-sending',
      'admin-the-sending-create',
    ])

    return true
  }

  // Админка - Клиенты. Функционал выгрузки клиентов
  if (permission === userActions.CLIENTS_EXCEL_EXPORT) {
    helpers.allowActionOnRoutes(userActions.CLIENTS_EXCEL_EXPORT, [
      'admin-clients',
    ])

    return true
  }

  return false
}
