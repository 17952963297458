const routes = [
  {
    path: '/online-school/settings',
    name: 'admin-online-school-settings',
    meta: {
      accessControlGroup: 'online-school-settings',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/OnlineSchoolSettings.vue'),
  },
  {
    path: '/online-school/orders',
    name: 'admin-online-school-orders',
    meta: {
      accessControlGroup: 'online-school-orders',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/orders/Orders.vue'),
  },
  {
    path: '/online-school/order/:orderId(\\d+)',
    name: 'admin-online-school-the-order',
    meta: {
      accessControlGroup: 'online-school-orders',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/orders/TheOrder.vue'),
  },
  {
    path: '/online-school/products',
    name: 'admin-online-school-products',
    meta: {
      accessControlGroup: 'online-school-products',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/OnlineSchoolProducts.vue'),
  },
  {
    path: '/online-school/product/:productId(\\d+)',
    name: 'admin-online-school-the-product',
    meta: {
      accessControlGroup: 'online-school-products',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/TheOnlineSchoolProduct.vue'),
  },
  {
    path: '/online-school/product/create',
    name: 'admin-online-school-the-product-create',
    meta: {
      accessControlGroup: 'online-school-products',
    },
    component: () =>
      import(
        '@admin/pages/admin/onlineSchool/TheOnlineSchoolProductCreate.vue'
      ),
  },
  {
    path: '/online-school/product-requests',
    name: 'admin-online-school-product-requests',
    meta: {
      accessControlGroup: 'online-school-product-requests',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/OnlineSchoolProductRequests.vue'),
  },
  {
    path: '/online-school/call-requests',
    name: 'admin-online-school-call-requests',
    meta: {
      accessControlGroup: 'online-school-call-requests',
    },
    component: () =>
      import('@admin/pages/admin/onlineSchool/OnlineSchoolCallRequests.vue'),
  },
]

export default routes
