import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class PromocodeGroup extends ApiBase {
  /**
   * @param {number} promocodeGroupId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(promocodeGroupId, parameters = {}) {
    return this._GET(
      `/promocode-groups/${promocodeGroupId}?${UrlHelper.param(parameters)}`,
    )
  }

  /**
   * @param {number} promocodeGroupId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(promocodeGroupId, data) {
    return this._PATCH(`/promocode-groups/${promocodeGroupId}`, data)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/promocode-groups`, data)
  }

  /**
   * @param {number} promocodeGroupId
   * @returns {Promise}
   */
  delete(promocodeGroupId) {
    return this._DELETE(`/promocode-groups/${promocodeGroupId}`)
  }
})()
