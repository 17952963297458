import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Библиотека
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setAbilityByModuleLibrary = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  const moduleLibrary = getActiveModule(activeModules, 'library')

  if (moduleLibrary !== undefined) {
    const librarySubjectsNames = routes.get('library-subjects')
    const librarySectionsNames = routes.get('library-sections')
    const libraryTrainingPrograms = routes.get('library-training-programs')
    const libraryModerationContent = routes.get('library-moderation-content')
    const libraryModerationQuestions = routes.get(
      'library-moderation-questions',
    )
    const libraryEtags = routes.get('library-etags')
    const libraryTrashTopics = routes.get('library-trash-topics')
    const libraryTrashContent = routes.get('library-trash-content')
    const libraryTrashQuestions = routes.get('library-trash-questions')

    if (
      permission === 'library_read_common' ||
      permission === 'library_read_private'
    ) {
      helpers.allowReadRoutes([
        ...librarySubjectsNames,
        ...librarySectionsNames,
        ...libraryTrainingPrograms,
      ])

      return true
    }

    if (permission === 'menu_library_subjects') {
      helpers.allowReadMenuItemForRoutes(librarySubjectsNames)

      return true
    }

    if (permission === 'menu_library_sections') {
      helpers.allowReadMenuItemForRoutes(librarySectionsNames)

      return true
    }

    if (permission === 'menu_library_training_program') {
      helpers.allowReadMenuItemForRoutes(libraryTrainingPrograms)

      return true
    }

    if (permission === 'menu_library_tags') {
      helpers.allowReadMenuItemForRoutes(libraryEtags)
      helpers.allowCrudRoutes(libraryEtags)

      return true
    }

    if (permission === 'menu_library_trash_topics') {
      helpers.allowReadMenuItemForRoutes(libraryTrashTopics)
      helpers.allowCrudRoutes(libraryTrashTopics)

      return true
    }

    if (permission === 'menu_library_trash_content') {
      helpers.allowReadMenuItemForRoutes(libraryTrashContent)
      helpers.allowCrudRoutes(libraryTrashContent)

      return true
    }

    if (permission === 'menu_library_trash_questions') {
      helpers.allowReadMenuItemForRoutes(libraryTrashQuestions)
      helpers.allowCrudRoutes(libraryTrashQuestions)

      return true
    }

    if (
      [
        'moderation_approved',
        'moderation_oncheck',
        'moderation_onedit',
        'moderation_onread',
      ].includes(permission)
    ) {
      if (permission === 'moderation_approved') {
        helpers.allowActionOnRoutes(userActions.READ_LIBRARY_APPROVED, [
          ...libraryModerationContent,
          ...libraryModerationQuestions,
        ])
      }

      if (permission === 'moderation_oncheck') {
        helpers.allowActionOnRoutes(userActions.READ_LIBRARY_MODERATION, [
          ...libraryModerationContent,
          ...libraryModerationQuestions,
        ])
      }

      if (permission === 'moderation_onedit') {
        helpers.allowActionOnRoutes(userActions.READ_LIBRARY_FIX_REQUIRED, [
          ...libraryModerationContent,
          ...libraryModerationQuestions,
        ])
      }

      if (permission === 'moderation_onread') {
        helpers.allowActionOnRoutes(userActions.READ_LIBRARY_PROOFREADING, [
          ...libraryModerationContent,
          ...libraryModerationQuestions,
        ])
      }

      helpers.allowCrudRoutes([
        ...libraryModerationContent,
        ...libraryModerationQuestions,
      ])

      return true
    }

    if (permission === 'menu_library_moderation_content') {
      helpers.allowReadMenuItemForRoutes(libraryModerationContent)

      return true
    }

    if (permission === 'menu_library_moderation_questions') {
      helpers.allowReadMenuItemForRoutes(libraryModerationQuestions)

      return true
    }

    if (permission === 'library_write_common') {
      helpers.allowActionOnRoutes(userActions.WRITE_LIBRARY_COMMON, [
        ...librarySubjectsNames,
        ...librarySectionsNames,
        ...libraryTrainingPrograms,
      ])

      helpers.allowCrudRoutes([
        ...librarySubjectsNames,
        ...librarySectionsNames,
        ...libraryTrainingPrograms,
      ])

      return true
    }

    if (permission === 'library_write_private') {
      helpers.allowActionOnRoutes(userActions.WRITE_LIBRARY_PRIVATE, [
        ...librarySubjectsNames,
        ...librarySectionsNames,
        ...libraryTrainingPrograms,
      ])

      helpers.allowCrudRoutes([
        ...librarySubjectsNames,
        ...librarySectionsNames,
        ...libraryTrainingPrograms,
      ])

      return true
    }
  }

  return false
}
