import { rowsPerPage } from '@admin/const/onlineLesson/rooms/rowsPerPage'
import { Pagination } from '@admin/helpers/admin/CompositePagination'
import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} data
   */
  setOnlineLessonRooms(state, data) {
    state.rooms = data.map(({ id, name }) => ({
      id,
      name,
    }))
  },

  /**
   * @param {Object} state
   * @param {Object} headers
   */
  setOnlineLessonRoomsPagination(state, headers) {
    const compositePagination = new Pagination({
      rowsPerPage,
    })

    compositePagination.updateFromHeaders(headers)

    const paginationState = state.roomsPagination

    if (paginationState !== null) {
      compositePagination.setRowsPerPage(paginationState.rowsPerPage)
    }

    state.roomsPagination = compositePagination
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {number} args.value
   */
  setOnlineLessonRoomsPaginationRowsPerPage(state, { value }) {
    state.roomsPagination.setRowsPerPage(value)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string|null} payload.name
   * @param {string|null} payload.lessonUrl
   * @param {string|null} payload.additionalInfo
   */
  setTheOnlineLessonRoom(state, { name, lessonUrl, additionalInfo }) {
    state.theRoom.name = name
    state.theRoom.lessonUrl = lessonUrl
    state.theRoom.additionalInfo = additionalInfo
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.key
   * @param {string|null} payload.value
   */
  setTheOnlineLessonRoomValue(state, { key, value }) {
    Vue.set(state.theRoom, key, value)
  },
}
