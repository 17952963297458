<template>
  <div class="mb-4">
    <UiKitFormField :label="t('policy_file.label')">
      <UiKitUploadAny
        v-model="policyFile"
        class="mb-4"
        @error="onErrorUpload"
      />
    </UiKitFormField>
  </div>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitUploadAny from '@admin/ui/admin/UiKitUploadAny.vue'
import { NotifyMixin } from 'Helpers/mixins/NotifyMixin'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemQuestions',

  components: {
    UiKitUploadAny,
    UiKitFormField,
  },

  mixins: [
    NotifyMixin,
  ],

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    policyFile: {
      get() {
        return this.localItem.policyFile
      },

      set(value) {
        this.localItem.policyFile = value

        this.emitChange()
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onErrorUpload(message) {
      this.notifyMethods().showError(`${this.t('load_file')}: ${message}`)
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.questions.${name}`)
    },
  },
})
</script>
