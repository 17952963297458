<template>
  <div :class="[$style.error, 'text-wrap']">
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitFormFieldError',

  props: {
    text: {
      type: String,
      default: '',
    },
  },
})
</script>

<style module lang="scss">
@import '~@admin/scss/bootstrap/variables';

.error {
  margin-top: 3px;
  line-height: 16px;
  color: $bright-red;
  letter-spacing: 0.01em;
  opacity: 0.7;

  @include font-size(12px);
}
</style>
