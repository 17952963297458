import ApiBase from 'ApiRest/ApiBase'
import convertEmptyDataToDefaultValue from 'Helpers/ConvertEmptyDataToDefaultValue'
import UrlHelper from 'Helpers/UrlHelper'
import { cloneDeep } from 'lodash'

export default new (class extends ApiBase {
  /**
   * @private
   * @param {Object} data
   * @returns {Object}
   */
  normalizeData(data) {
    const isDataEmpty = Object.keys(data).length === 0

    if (isDataEmpty) {
      return data
    }

    const normalizedData = cloneDeep(data)

    if (normalizedData.student.citizenship) {
      normalizedData.student.passport = ApiBase.normalizePassport(
        normalizedData.student.passport,
        normalizedData.student.citizenship.id,
      )
    }

    convertEmptyDataToDefaultValue(normalizedData)

    return normalizedData
  }

  /**
   * @param {number} requestId
   * @param {Object} parameters
   * @returns {Promise}
   */

  get(requestId, parameters = {}) {
    const url = `/enrollment/request/${requestId}?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {number} requestId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(requestId, data) {
    const url = `/enrollment/request/${requestId}`
    const normalizedData = this.normalizeData(data)

    return this._PATCH(url, normalizedData)
  }
})()
