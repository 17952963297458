import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOption(state, { path, value }) {
    Vue.set(state.option, path, value)
  },

  setTopicValue(state, { key, value }) {
    Vue.set(state.theTopic, key, value)
  },

  setQuestionValue(state, { key, value }) {
    Vue.set(state.theQuestion, key, value)
  },

  setChangeTopicWithItems(state, payload) {
    state.topicsWithItems = payload
  },

  setTopicWithItems(state, payload) {
    state.topicsWithItems = payload
  },

  setTheTopic(state, payload) {
    state.theTopic = payload
  },

  setTheQuestion(state, payload) {
    state.theQuestion = payload
  },
}
