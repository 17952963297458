import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setParameter(state, payload) {
    state.parameter = payload
  },

  setParameterId(state, payload) {
    state.parameterId = payload
  },

  setParameterValue(state, { path, value }) {
    set(state.parameter, path, value)
  },

  addOption(state, payload) {
    state.parameter.options.push(payload)
  },

  removeOption(state, index) {
    state.parameter.options.splice(index, 1)
  },

  setOptionValue(state, { index, path, value }) {
    Vue.set(state.parameter.options[index], path, value)
  },
}
