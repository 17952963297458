<template>
  <UiKitToggleSwitch
    v-if="!isLoading"
    :checked="isDark"
    @switch="switchTheme"
  />
</template>

<script>
import UiKitToggleSwitch from '@admin/ui/admin/UiKitToggleSwitch.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ThemeToggle',
  components: {
    UiKitToggleSwitch,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('admin/theme', [
      'variants',
      'currentTheme',
    ]),

    ...mapGetters('admin/theme', {
      isDark: 'isDarkTheme',
    }),

    light() {
      return this.variants.light
    },

    dark() {
      return this.variants.dark
    },
  },

  methods: {
    ...mapActions({
      setTheme: 'admin/theme/setTheme',
    }),

    switchTheme(value) {
      const themeColor = value ? this.dark : this.light

      this.setTheme(themeColor)
    },
  },
}
</script>
