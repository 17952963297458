import ApiCertificationsSchedule from 'ApiRest/Api/FamilyEducation/CertificationsSchedule'
import ApiTheCertificationSchedule from 'ApiRest/Api/FamilyEducation/TheCertificationSchedule'
import ApiOption from 'ApiRest/Api/Option'
import Constants from 'Constants'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchFamilyCertificationSchools({ commit }) {
    const params = {
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    const { data } = await ApiOption.getSchools(params)

    commit('setFamilyCertificationSchools', data)
  },

  async fetchFamilyCertificationsSchedule({ commit }, params) {
    const response = await ApiCertificationsSchedule.get(params)

    commit('setFamilyCertificationsSchedule', response.data)

    return response
  },

  async fetchFamilyCertificationSchedule({ commit }, scheduleId) {
    const { data } = await ApiTheCertificationSchedule.get(scheduleId)

    commit('setFamilyCertificationSchedule', data)
  },

  async createFamilyCertificationSchedule({ commit }, payload) {
    const { data } = await ApiTheCertificationSchedule.post(payload)

    commit('setFamilyCertificationSchedule', data)
  },

  async updateFamilyCertificationSchedule({ commit }, { scheduleId, payload }) {
    const { data } = await ApiTheCertificationSchedule.patch(
      scheduleId,
      payload,
    )

    commit('setFamilyCertificationSchedule', data)
  },

  async deleteFamilyCertificationSchedule(context, scheduleId) {
    await ApiTheCertificationSchedule.delete(scheduleId)
  },
}
