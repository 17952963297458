const routes = [
  {
    path: '',
    name: 'admin-main',
    component: () =>
      import(
        /* webpackChunkName: "admin-main" */ '@admin/pages/admin/AdminMain.vue'
      ),
  },
]

export default routes
