import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOnlineSchoolProduct = new (class extends ApiBase {
  /**
   * @param {number} id
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(id, parameters) {
    return this._GET(
      `/online-school/product/${id}?${UrlHelper.param(parameters)}`,
    )
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @param {Object} parameters
   * @returns {Promise}
   */
  put(id, data, parameters) {
    return this._PUT(
      `/online-school/product/${id}?${UrlHelper.param(parameters)}`,
      data,
    )
  }

  /**
   * @param {Object} data
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(data, parameters) {
    return this._POST(
      `/online-school/product?${UrlHelper.param(parameters)}`,
      data,
    )
  }
})()
