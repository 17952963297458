import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOnlineLessonRoomList = new (class extends ApiBase {
  /**
   * Получение комнат
   * @param {Object} params
   * @returns {Promise<Object>}
   */
  get(params = {}) {
    return this._GET(`/online-lessons/online-rooms?${UrlHelper.param(params)}`)
  }
})()
