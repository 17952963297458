const routes = [
  {
    path: '/statement',
    name: 'admin-the-statement',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-statement" */
        '@admin/pages/admin/statement/TheStatement.vue'
      ),
  },
]

export default routes
