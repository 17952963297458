import { Pagination } from '@admin/helpers/admin/CompositePagination'

export default {
  setOrders(state, payload) {
    state.orders = payload
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const paginationState = state.pagination

    if (paginationState !== null) {
      compositePagination.setRowsPerPage(paginationState.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, filters) {
    state.filters = filters
  },
}
