export default () => ({
  familyCertificationSchools: [],
  certificationsSchedule: [],

  certificationSchedule: {
    id: null,
    name: null,
    dateBegin: null,
    dateEnd: null,
    school: null,
  },
})
