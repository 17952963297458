export default [
  {
    path: '/payments/services',
    name: 'admin-payment-services',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-services" */ '@admin/pages/admin/payment/PaymentServices.vue'
      ),
  },
  {
    path: '/payments/services/create',
    name: 'admin-the-payment-service-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-payment-services" */ '@admin/pages/admin/payment/ThePaymentService.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/services/:serviceId(\\d+)',
    name: 'admin-the-payment-service',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-payment-services" */ '@admin/pages/admin/payment/ThePaymentService.vue'
      ),
  },
]
