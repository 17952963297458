import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportDepartment = new (class extends ApiBase {
  /**
   * @param {number} departmentId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(departmentId, parameters = {}) {
    const url = `/support/departments/${departmentId}?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload = {}, parameters = {}) {
    const url = `/support/departments?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }

  /**
   * @param {number} departmentId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(departmentId, payload, parameters = {}) {
    const url = `/support/departments/${departmentId}?${UrlHelper.param(
      parameters,
    )}`

    return this._PATCH(url, payload)
  }

  /**
   * @param {number} departmentId
   * @param {Object} parameters
   * @returns {Promise}
   */
  delete(departmentId, parameters) {
    const url = `/support/departments/${departmentId}?${UrlHelper.param(
      parameters,
    )}`

    return this._DELETE(url)
  }
})()
