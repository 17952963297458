const routes = [
  {
    path: '/family-certifications',
    name: 'admin-family-certifications',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-family-certifications" */ '@admin/pages/admin/familyCertification/FamilyCertifications.vue'
      ),
  },
  {
    path: '/family-certification/create',
    name: 'admin-the-family-certification-create',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification" */ '@admin/pages/admin/familyCertification/TheFamilyCertification.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/family-certification/:certificationId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification" */ '@admin/pages/admin/familyCertification/TheFamilyCertification.vue'
      ),
    children: [
      {
        path: 'statement/:studentId(\\d+)',
        name: 'admin-the-family-statement-personal',
        meta: {
          accessControlGroup: 'family-certification',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-the-family-statement-personal" */ '@admin/pages/admin/familyCertification/TheFamilyPersonalStatement.vue'
          ),
      },
      {
        path: '',
        name: 'admin-the-family-certification',
        meta: {
          accessControlGroup: 'family-certification',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-the-family-certification-statements" */ '@admin/pages/admin/familyCertification/TheFamilyStatement.vue'
          ),
      },
    ],
  },
  {
    path: '/family-certifications-schedule',
    name: 'admin-family-certifications-schedule',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-family-certifications-schedule" */ '@admin/pages/admin/familyCertification/FamilyCertificationsSchedule.vue'
      ),
  },
  {
    path: '/family-certification-schedule/create',
    name: 'admin-the-family-certification-schedule-create',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification-schedule" */ '@admin/pages/admin/familyCertification/TheFamilyCertificationSchedule.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/family-certification-schedule/:scheduleId(\\d+)',
    name: 'admin-the-family-certification-schedule',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification-schedule" */ '@admin/pages/admin/familyCertification/TheFamilyCertificationSchedule.vue'
      ),
  },
  {
    path: '/family-certification-requests',
    name: 'admin-family-certification-requests',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-family-certification-requests" */ '@admin/pages/admin/familyCertification/FamilyCertificationRequests.vue'
      ),
  },
  {
    path: '/family-certification-request/:requestId(\\d+)',
    name: 'admin-the-family-certification-request',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification-request" */ '@admin/pages/admin/familyCertification/TheFamilyCertificationRequest.vue'
      ),
  },
  {
    path: '/family-certification-request-draft',
    name: 'admin-the-family-certification-request-draft',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-family-certification-request-draft" */ '@admin/pages/admin/familyCertification/TheFamilyCertificationRequestDraft.vue'
      ),
  },
  {
    path: '/family-certification-statement',
    name: 'admin-family-certification-statement',
    component: () =>
      import(
        /* webpackChunkName: "admin-family-certification-statement" */ '@admin/pages/admin/familyCertification/FamilyCertificationStatement.vue'
      ),
  },
]

export default routes
