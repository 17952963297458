import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheIdea(state, payload) {
    state.theIdea = payload
  },

  setTheIdeaValue(state, { path, value }) {
    Vue.set(state.theIdea, path, value)
  },

  setOption(state, { path, value }) {
    Vue.set(state.options, path, value)
  },

  setLogs(state, payload) {
    state.logs = payload
  },
}
