export default [
  {
    path: '/payments/subscriptions',
    name: 'admin-payment-subscriptions',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-subscriptions" */ '@admin/pages/admin/payment/subscriptions/Subscriptions.vue'
      ),
  },
  {
    path: '/payments/subscriptions/:subscriptionId(\\d+)',
    name: 'admin-payment-the-subscription',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-subscription" */ '@admin/pages/admin/payment/subscriptions/TheSubscription.vue'
      ),
  },
  {
    path: '/payments/subscriptions/create',
    name: 'admin-payment-create-subscription',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-create-subscription" */ '@admin/pages/admin/payment/subscriptions/CreateSubscription.vue'
      ),
  },
]
