import LayoutAdminMain from '@admin/layouts/AdminMain.vue'
import adminMain from '@admin/routes/admin/adminMain'
import administrating from '@admin/routes/admin/administrating'
import { courseManagementRoutes } from '@admin/routes/admin/courseManagement'
import fullTimeStatement from '@admin/routes/admin/fullTimeStatement'
import { showcaseOnlineLessonRoutes } from '@admin/routes/admin/showcaseOnlineLesson'
import { supportRoutes } from '@admin/routes/admin/support'

import analytics from './analytics'
import auth from './auth'
import certification from './certification'
import client from './client'
import contract from './contract'
import credits from './credits'
import ctp from './ctp'
import event from './event'
import familyCertification from './familyCertification'
import groups from './groups'
import homework from './homework'
import incomeDiagnostics from './incomeDiagnostics'
import library from './library'
import messages from './messages'
import news from './news'
import onlineLesson from './onlineLesson'
import onlineSchool from './onlineSchool'
import payment from './payment'
import price from './price'
import productManagement from './productManagement'
import rating from './rating'
import request from './request'
import sendings from './sendings'
import settings from './settings'
import specialStatement from './specialStatement'
import statement from './statement'
import student from './student'
import test from './test'
import wiki from './wiki'

const routes = {
  path: '',
  component: LayoutAdminMain,

  children: [
    ...auth,
    ...analytics,
    ...administrating,
    ...adminMain,
    ...messages,
    ...sendings,
    ...certification,
    ...credits,
    ...familyCertification,
    ...groups,
    ...client,
    ...contract,
    ...courseManagementRoutes,
    ...ctp,
    ...event,
    ...library,
    ...incomeDiagnostics,
    ...news,
    ...onlineLesson,
    ...payment,
    ...price,
    ...productManagement,
    ...request,
    ...rating,
    ...settings,
    ...statement,
    ...fullTimeStatement,
    ...student,
    ...test,
    ...homework,
    ...wiki,
    ...supportRoutes,
    ...showcaseOnlineLessonRoutes,
    ...specialStatement,
    ...onlineSchool,
  ],
}

export default routes
