import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiEducontLog = new (class extends ApiBase {
  /**
   * Получение логов ЭДУКОНТ
   * @param {Object} params
   * @returns {Promise<Object>}
   */
  get(params = {}) {
    return this._GET(
      `/educont/statistic/transactions/list?${UrlHelper.param(params)}`,
    )
  }
})()
