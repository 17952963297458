import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

/**
 * @typedef {{
 * bookId: string,
 * inventoryNumber: string,
 * name: string,
 * author: string,
 * publisher: string,
 * publishingCity: string,
 * publicationYear: number,
 * receivedDate: string,
 * returnDate: string,
 * cost: float
 * }} LibraryAdminItemI
 */

export const ApiLibraryAdminItems = new (class extends ApiBase {
  /**
   * Список библиотечных книг ученика
   * для просмотра из панели управления
   * @param {Object} args
   * @param {number} args.studentId
   * @param {Object} args.params
   * @returns {Promise<{data: LibraryAdminItemI[]}>}
   */
  get({ studentId, params = {} }) {
    const urlParams = UrlHelper.param(params)
    const url = `/school-library/student/${studentId}?${urlParams}`

    return this._GET(url)
  }
})()
