<template>
  <BlockEdit>
    <div class="d-flex align-items-center no-wrap">
      <div v-handle class="p-3 handle cursor-move">
        <i class="fas fa-bars"></i>
      </div>

      <RatingStars :rate="item.rate" disabled class="mr-2" />
      <div class="text-truncate mr-2">{{ item.authorName }}</div>
    </div>

    <div class="d-flex">
      <UiKitButton class="square" @click="$emit('edit')">
        <i class="fas fa-pen"></i>
      </UiKitButton>

      <UiKitButton class="square" @click="$emit('remove')">
        <i class="fas fa-times"></i>
      </UiKitButton>
    </div>
  </BlockEdit>
</template>

<script>
import RatingStars from '@admin/components/admin/productManagement/product/content/items/reviews/RatingStars.vue'
import BlockEdit from '@admin/components/admin/productManagement/product/common/BlockEdit.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import { ElementMixin, HandleDirective } from 'vue-slicksort'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReviewItem',

  directives: {
    handle: HandleDirective,
  },

  components: {
    BlockEdit,
    UiKitButton,
    RatingStars,
  },

  mixins: [
    ElementMixin,
  ],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
</script>
