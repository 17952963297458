const routes = [
  {
    path: '/group/create',
    name: 'admin-the-group-create',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-group" */ '@admin/pages/admin/group/TheGroup.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/group/:groupId(\\d+)',
    name: 'admin-the-group',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-group" */ '@admin/pages/admin/group/TheGroup.vue'
      ),
  },
]

export default routes
