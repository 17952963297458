export default [
  {
    path: 'auth/any/7538',
    name: 'admin-auth-any',
    component: () =>
      import(
        /* webpackChunkName: "admin-auth-any" */ '@admin/pages/admin/auth/AuthAny.vue'
      ),
  },
]
