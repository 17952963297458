const routes = [
  {
    path: '/events',
    name: 'admin-events',
    meta: {
      accessControlGroup: 'event',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-events" */ '@admin/pages/admin/event/Events.vue'
      ),
  },
  {
    path: '/events/create',
    name: 'admin-the-event-create',
    meta: {
      accessControlGroup: 'event',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-event" */ '@admin/pages/admin/event/TheEvent.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/events/:eventId(\\d+)',
    name: 'admin-the-event',
    meta: {
      accessControlGroup: 'event',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-event" */ '@admin/pages/admin/event/TheEvent.vue'
      ),
  },
]

export default routes
