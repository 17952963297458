import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPaymentServices(state, payload) {
    state.services = payload
  },

  setTheService(state, payload) {
    state.theService = payload
  },

  setTheServiceValue(state, { key, value }) {
    Vue.set(state.theService, key, value)
  },
}
