const routes = [
  {
    path: '/special-statement',
    name: 'admin-special-statement',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-special-statement" */
        '@admin/pages/admin/specialStatement/SpecialStatements.vue'
      ),
  },
  {
    path: '/special-statement/create',
    name: 'admin-the-special-statement-create',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-special-statement" */
        '@admin/pages/admin/specialStatement/TheSpecialStatementCreate.vue'
      ),
  },
  {
    path: '/special-statement/edit/:specialStatementId',
    name: 'admin-the-special-statement-edit',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-special-statement" */
        '@admin/pages/admin/specialStatement/TheSpecialStatementCreate.vue'
      ),
  },
  {
    path: '/special-statement/:specialStatementId(\\d+)',
    name: 'admin-the-special-statement',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-special-statement" */ '@admin/pages/admin/specialStatement/TheSpecialStatement.vue'
      ),
  },
]

export default routes
