<template>
  <div>
    <div v-if="!isLoading" class="uiKit-navigation">
      <div
        v-if="navigationTitle"
        class="uiKit-navigation-title d-flex cursor-pointer"
        @click="toggleBar"
      >
        <span class="fa fa-bars" :class="getCssClassForBarIcon()"></span>

        <h2 class="m-0">{{ navigationTitle }}</h2>
      </div>

      <div v-show="isBarActive">
        <template v-for="(item, itemIndex) in localNavigation">
          <UiKitNavigationGroup
            v-if="item"
            :key="itemIndex"
            :item="item"
            @toggle="(e) => processToggle(itemIndex, e)"
          />
        </template>
      </div>
    </div>

    <LoadingElement v-else />
  </div>
</template>

<script>
import { userActions as AccessControlUserActions } from '@admin/accessControl/userActions'
import LoadingElement from '@admin/ui/admin/LoadingElement.vue'
import UiKitNavigationGroup from '@admin/ui/admin/UiKitNavigationGroup.vue'
import { cloneDeep } from 'lodash'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'UiKitNavigation',

  components: {
    UiKitNavigationGroup,
    LoadingElement,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
      localNavigation: [],
    }
  },

  computed: {
    ...mapState('admin/navigation', [
      'navigation',
      'isBarActive',
    ]),

    ...mapGetters('admin/theme', [
      'isDarkTheme',
    ]),

    navigationTitle() {
      return this.title ? this.title : this.$t('ui.props_default.menu')
    },

    canEnableChat() {
      return this.$can(AccessControlUserActions.ENABLE_CHAT, this.$route.name)
    },
  },

  watch: {
    navigation: {
      handler() {
        this.localNavigation = cloneDeep(this.navigation)
      },

      immediate: true,
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('admin/navigation', [
      'setNavBarState',
      'getNavBarState',
    ]),

    ...mapMutations('admin/navigation', [
      'setNavigation',
    ]),

    toggleBar() {
      this.setNavBarState(!this.isBarActive)
    },

    async init() {
      this.isLoading = true
      try {
        this.constructNavigation()
        this.setVisibleGroup()
        await this.getNavBarState()
        this.setNavigation(cloneDeep(this.localNavigation))
      } finally {
        this.isLoading = false
      }
    },

    getCssClassForBarIcon() {
      return [
        'burgerBtn',
        {
          burgerBtnActiveDark: this.isBarActive && this.isDarkTheme,
          burgerBtnActiveLight: this.isBarActive && !this.isDarkTheme,
        },
      ]
    },

    constructNavigation() {
      // Запускаем метод построения меню
      const menu = this.buildMenu(cloneDeep(this.localNavigation))

      this.localNavigation = this.removeEmptyMenuGroups(menu)

      if (!this.canEnableChat) {
        this.localNavigation = this.localNavigation.filter(
          (nav) => nav.routeName !== 'admin-messages',
        )
      }
    },

    buildMenu(navigationItems) {
      const result = []

      const addChildItems = (navItem) => {
        if (navItem.items) {
          navItem.items = this.buildMenu(cloneDeep(navItem.items))

          if (navItem.routeName || navItem.items?.length) {
            result.push(navItem)
          }
        } else {
          if (navItem.routeName) {
            result.push(navItem)
          }
        }
      }

      navigationItems.forEach((navItem) => {
        // Если у пункта меню есть поле routeName
        if (navItem.routeName) {
          if (this.canAccessRoute(navItem.routeName) || navItem.items) {
            // Если есть права на чтение раздела
            addChildItems(navItem)
          }
        } else {
          // Если у пункта меню нет поля routeName
          addChildItems(navItem)
        }
      })

      return result
    },

    // Пробегаем по массиву меню, если у пункта меню в items пустой массив то этот пункт меню не отображаем
    removeEmptyMenuGroups(navItems) {
      const result = []

      navItems.forEach((navItem) => {
        if (navItem.items?.length) {
          navItem.items = this.removeEmptyMenuGroups(cloneDeep(navItem.items))

          if (
            (navItem.routeName && this.canAccessRoute(navItem.routeName)) ||
            navItem.items?.length
          ) {
            result.push(navItem)
          }
        } else {
          if (navItem.routeName && this.canAccessRoute(navItem.routeName)) {
            result.push(navItem)
          }
        }
      })

      return result
    },

    /**
     * @param {string} routeName
     * @returns {boolean}
     */
    canAccessRoute(routeName) {
      return (
        this.$can(AccessControlUserActions.READ, routeName) &&
        this.$can(AccessControlUserActions.READ_MENU_ITEM, routeName)
      )
    },

    /**
     * Update groups visibility
     * @param {number} itemIndex
     * @param {{}} data
     */
    processToggle(itemIndex, data) {
      this.hideAllGroups()
      this.$set(this.localNavigation, itemIndex, data)
      this.setNavigation(cloneDeep(this.localNavigation))
    },

    hideAllGroups() {
      Object.keys(this.localNavigation).forEach((key) => {
        this.localNavigation[key].visible = false
      })
    },

    // TODO: повторяющийся код в циклах
    setVisibleGroup() {
      const itemsCheck = (group) => {
        for (let index = 0; index < group.items.length; index++) {
          const item = group.items[index]
          const { routeName } = item
          const { items } = item

          if (items) {
            const hasVisibleChildren = itemsCheck(item)

            if (hasVisibleChildren) {
              group.visible = true

              return true
            }
          }

          if (routeName) {
            const url = this.$router.resolve({
              name: routeName,
            }).href

            if (this.$route.path.substr(0, url.length) === url) {
              group.visible = true

              return true
            }
          }
        }

        return false
      }

      for (
        let groupIndex = 0;
        groupIndex < this.localNavigation.length;
        groupIndex++
      ) {
        const group = this.localNavigation[groupIndex]
        const { items } = group

        if (items) {
          if (group.routeName) {
            const url = this.$router.resolve({
              name: group.routeName,
            }).href

            if (this.$route.path.substr(0, url.length) === url) {
              group.visible = true
            }
          }

          const hasVisibleChildren = itemsCheck(group)

          if (hasVisibleChildren) {
            group.visible = true
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.burgerBtn {
  width: 19px;
  padding-top: 4px;
  margin-right: 10px;
  font-size: 21px;
  color: #0076d6;
  background: none;
  border: none;
}

.burgerBtnActiveLight {
  color: #8b939a;
}

.burgerBtnActiveDark {
  color: #8e949f;
}

.uiKit-navigation {
  padding: 0 20px;
  font-family: $fontFamilySans;
  background: var(--uiKit-navigation-background);
  border-radius: 8px;
  box-shadow: 0 6px 40px var(--uiKit-navigation-box-shadow);

  &-title {
    padding: 20px 0;
    line-height: 120%;
    color: var(--uiKit-navigation-titleColor);
    text-transform: uppercase;
    letter-spacing: 0.03em;

    @include font-size(11px);
  }
}
</style>
