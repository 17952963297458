export default [
  {
    path: '/settings/trigger-sendings',
    name: 'admin-settings-trigger-sendings',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-trigger-sendings" */ '@admin/pages/admin/settings/TriggerSendings.vue'
      ),
  },
  {
    path: '/settings/trigger-sending-create',
    name: 'admin-settings-create-trigger-sending',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-trigger-sending" */ '@admin/pages/admin/settings/TheTriggerSending.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/trigger-sendings/:triggerSendingId(\\d+)',
    name: 'admin-settings-the-trigger-sending',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-settings-the-trigger-sending" */ '@admin/pages/admin/settings/TheTriggerSending.vue'
      ),
  },
]
