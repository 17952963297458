import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOption(state, { key, value }) {
    state.option[key] = value
  },

  setSubsidyPrices(state, payload) {
    state.subsidyPrices = payload
  },

  setTheSubsidyPrice(state, payload) {
    state.theSubsidyPrice = payload
  },

  setTheSubsidyPriceValue(state, { key, value }) {
    Vue.set(state.theSubsidyPrice, key, value)
  },

  setPaidPrices(state, payload) {
    state.paidPrices = payload
  },

  setThePaidPrice(state, payload) {
    state.thePaidPrice = payload
  },

  setThePaidPriceValue(state, { key, value }) {
    Vue.set(state.thePaidPrice, key, value)
  },

  setIndividualSubsidyPrices(state, payload) {
    state.individualSubsidyPrices = payload
  },

  setTheIndividualSubsidyPrice(state, payload) {
    state.theIndividualSubsidyPrice = payload
  },

  setTheIndividualSubsidyPriceValue(state, { key, value }) {
    Vue.set(state.theIndividualSubsidyPrice, key, value)
  },

  setIndividualPaidPrices(state, payload) {
    state.individualPaidPrices = payload
  },

  setTheIndividualPaidPrice(state, payload) {
    state.theIndividualPaidPrice = payload
  },

  setTheIndividualPaidPriceValue(state, { key, value }) {
    Vue.set(state.theIndividualPaidPrice, key, value)
  },
}
