import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/family-certifications/option/tests?${UrlHelper.param(parameters)}`,
    )
  }
})()
