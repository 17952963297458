const routes = [
  {
    path: '/tests',
    name: 'admin-tests',
    meta: {
      accessControlGroup: 'test',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-tests" */ '@admin/pages/admin/test/Tests.vue'
      ),
  },
  {
    path: '/tests/create',
    name: 'admin-the-test-create',
    meta: {
      accessControlGroup: 'test',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test" */ '@admin/pages/admin/test/TheTest.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/tests/:testId(\\d+)',
    name: 'admin-the-test',
    meta: {
      accessControlGroup: 'test',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test" */ '@admin/pages/admin/test/TheTest.vue'
      ),
  },
  {
    path: '/tests/check',
    name: 'admin-tests-check',
    meta: {
      accessControlGroup: 'test-check',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-tests-check" */ '@admin/pages/admin/test/TestsCheck.vue'
      ),
  },
  {
    path: '/tests/check/:testCheckId(\\d+)/:attemptId(\\d+)?',
    name: 'admin-the-test-check',
    meta: {
      accessControlGroup: 'test-check',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test-check" */ '@admin/pages/admin/test/TheTestCheck.vue'
      ),
  },
  {
    path: '/tests/check/return/:testCheckId(\\d+)',
    name: 'admin-the-test-return',
    meta: {
      accessControlGroup: 'test-check',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test-return" */ '@admin/pages/admin/test/TheTestReturn.vue'
      ),
  },
  {
    path: '/tests/check/new/:testCheckId(\\d+)',
    name: 'admin-the-test-return-new-variant',
    meta: {
      accessControlGroup: 'test-check',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test-return-new-variant" */ '@admin/pages/admin/test/TheTestReturnNewVariant.vue'
      ),
  },
  {
    path: '/tests/check/reset/:testCheckId(\\d+)',
    name: 'admin-the-test-nullify',
    meta: {
      accessControlGroup: 'test-check',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test-nullify" */ '@admin/pages/admin/test/TheTestNullify.vue'
      ),
  },
  {
    path: '/tests/payment',
    name: 'admin-tests-payment',
    meta: {
      accessControlGroup: 'test-payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-tests-payment" */ '@admin/pages/admin/test/TestsPayment.vue'
      ),
  },
  {
    path: '/tests/payment/create',
    name: 'admin-the-test-payment-create',
    meta: {
      accessControlGroup: 'test-payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-test-payment" */ '@admin/pages/admin/test/TheTestPayment.vue'
      ),
  },
]

export default routes
