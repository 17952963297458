import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setQuestions(state, payload) {
    state.questions = payload
  },

  setTheQuestion(state, payload) {
    state.theQuestion = payload
  },

  setTheQuestionValue(state, { path, value }) {
    Vue.set(state.theQuestion, path, value)
  },

  setTheQuestionInitialStatus(state, payload) {
    state.theQuestionInitialStatus = payload
  },

  setTheNewQuestion(state, payload) {
    state.theNewQuestion = payload
  },

  setTheNewQuestionValue(state, { path, value }) {
    Vue.set(state.theNewQuestion, path, value)
  },

  setOption(state, { path, value }) {
    Vue.set(state.options, path, value)
  },

  setLogs(state, payload) {
    state.logs = payload
  },

  setQuestionsInWorkCount(state, payload) {
    state.questionsInWorkCount = payload
  },
}
