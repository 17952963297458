import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class Grade extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/price/extramural/education/paid/grade/options?${UrlHelper.param(
        parameters,
      )}`,
    )
  }
})()
