import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class List extends ApiBase {
  /**
   * @param {number} id
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(id, parameters = {}) {
    return this._GET(
      `/diagnostics/applications/${id}?${UrlHelper.param(parameters)}`,
    )
  }
})()
