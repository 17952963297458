const routes = [
  {
    path: '/clients',
    name: 'admin-clients',
    meta: {
      accessControlGroup: 'client',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-clients" */ '@admin/pages/admin/client/Clients.vue'
      ),
  },
  {
    path: '/clients/trash',
    name: 'admin-clients-trash',
    meta: {
      accessControlGroup: 'client',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-clients-trash" */ '@admin/pages/admin/client/ClientsTrash.vue'
      ),
    props: {
      isDeleted: true,
    },
  },
  {
    path: '/client/create',
    name: 'admin-the-client-create',
    meta: {
      accessControlGroup: 'client',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-client" */ '@admin/pages/admin/client/TheClient.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/client/:clientId(\\d+)',
    name: 'admin-the-client',
    meta: {
      accessControlGroup: 'client',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-client" */ '@admin/pages/admin/client/TheClient.vue'
      ),
  },
  {
    path: '/leads',
    name: 'admin-leads',
    meta: {
      accessControlGroup: 'client-demo',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-leads" */ '@admin/pages/admin/client/Leads.vue'
      ),
  },
  {
    path: '/lead/create',
    name: 'admin-the-lead-create',
    meta: {
      accessControlGroup: 'client-demo',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-lead" */ '@admin/pages/admin/client/TheLead.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/lead/:leadId(\\d+)',
    name: 'admin-the-lead',
    meta: {
      accessControlGroup: 'client-demo',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-lead" */ '@admin/pages/admin/client/TheLead.vue'
      ),
  },
]

export default routes
