import { conditions } from '@admin/accessControl/helpers'
import { userActions } from '@admin/accessControl/userActions'

/**
 * Разрешения по произвольному набору "дополнительных данных"
 * @param {Object} data
 * @param {Object} data.extraData
 * @param {Array} data.permissions
 * @param {Object} utils
 * @param {Function} utils.can
 * @param {Function} utils.cannot
 */
export const setAbilityByExtraData = (
  { extraData = {}, permissions },
  { can, cannot },
) => {
  const helpers = conditions({
    can,
    cannot,
  })

  if (permissions.includes('disabling_statement_periods')) {
    can(
      userActions.DISABLING_STATEMENT_PERIODS,
      'admin-the-full-time-statement',
    )
  }

  if (extraData.hasAccessPDStudent) {
    helpers.allowAccessPDStudentForRoutes([
      'admin-students',
      'admin-the-student',
      'admin-the-student-create',
      'admin-students-demo',
      'admin-student-the-statement',
      'admin-the-student-demo-create',
      'admin-the-student-demo',
      'admin-the-enrollment-request',
      'admin-students-educont',
      'admin-the-student-educont',
      'admin-the-client',
      'admin-clients',
      'admin-leads',
      'admin-the-lead',
      'admin-the-test-check',
      'admin-the-certification',
      'admin-product-management-orders',
      'admin-enrollment-requests-draft',
      'admin-administrating-zoom',
      'admin-enrollment-contracts',
      'admin-enrollment-requests',
      'admin-enrollment-requests-trash',
      'admin-support-questions',
      'admin-income-diagnostics-applications',
      'admin-payment-invoices',
      'admin-family-certification-requests',
      'admin-the-family-certification-request-draft',
      'admin-the-enrollment-contract',
      'admin-support-the-question',
      'admin-the-family-certification-request',
      'admin-product-management-the-order',
      'admin-the-income-diagnostics-application',
      'admin-support-the-idea',
    ])
  } else {
    cannot(userActions.ACCESS_PD_STUDENT, 'all')
  }

  if (extraData.hasAccessPDClient) {
    helpers.allowAccessPDClientForRoutes([
      'admin-students',
      'admin-the-student',
      'admin-students-demo',
      'admin-the-student-create',
      'admin-the-student-demo',
      'admin-student-the-statement',
      'admin-family-certification-requests',
      'admin-support-the-question',
      'admin-enrollment-contracts',
      'admin-enrollment-requests',
      'admin-enrollment-requests-trash',
      'admin-clients',
      'admin-product-management-orders',
      'admin-the-client',
      'admin-the-client-create',
      'admin-leads',
      'admin-the-lead',
      'admin-enrollment-requests-draft',
      'admin-income-diagnostics-applications',
      'admin-payment-invoices',
      'admin-support-questions',
      'admin-administrating-zoom',
      'admin-the-enrollment-request',
      'admin-the-enrollment-contract',
      'admin-the-family-certification-request',
      'admin-the-family-certification-request-draft',
      'admin-product-management-the-order',
      'admin-the-payment-invoice',
      'admin-the-income-diagnostics-application',
    ])
  } else {
    cannot(userActions.ACCESS_PD_CLIENT, 'all')
  }
}
