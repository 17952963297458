import { getDateTime } from 'Helpers/dateFormatHelper'

export default {
  ctps(state) {
    return state.ctpData.ctps
  },

  lastUpdatedTime(state) {
    return getDateTime(state.ctpData.lastUpdatedTime)
  },
}
