const wiki = [
  {
    path: '/wiki',
    name: 'admin-wiki',
    meta: {
      accessControlGroup: 'wiki',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-wiki" */ '@admin/pages/admin/wiki/Wiki.vue'
      ),
  },
  {
    path: '/wiki/:schoolId(\\d+)/:wikiId(\\d+)?',
    name: 'admin-the-wiki',
    meta: {
      accessControlGroup: 'wiki',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-wiki" */ '@admin/pages/admin/wiki/TheWiki.vue'
      ),
  },
]

export default wiki
