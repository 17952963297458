import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOrderList = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params) {
    const urlParams = UrlHelper.param(params)
    const url = `/orders?${urlParams}`

    return this._GET(url)
  }
})()
