import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportIdeaLogs = new (class extends ApiBase {
  /**
   * @param {number} ideaId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(ideaId, parameters) {
    const url = `/support/ideas/${ideaId}/logs?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
