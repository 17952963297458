import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSettings(state, payload) {
    state.settings = payload
  },

  setSettingsValue(state, { path, value }) {
    set(state.settings, path, value)
  },
}
