<template>
  <div class="d-flex align-items-center mt-2 mb-4">
    <div class="row">
      <div class="col-6">
        <UiKitFormField class="mb-4" :label="t('photo.label')">
          <UiKitUploadAny v-model="photo" />
        </UiKitFormField>

        <UiKitFormField class="mb-4" :label="t('rate.label')" required>
          <RatingStars v-model="rate" />
          <UiKitFormFieldErrors :errors="v$.review.rate.$errors" />
        </UiKitFormField>
      </div>
      <div class="col-6">
        <UiKitFormField class="mb-4" :label="t('author_name.label')" required>
          <UiKitInput
            v-model="authorName"
            :placeholder="t('author_name.placeholder')"
          />
          <UiKitFormFieldErrors :errors="v$.review.authorName.$errors" />
        </UiKitFormField>

        <UiKitFormField class="mb-4" :label="t('subtitle.label')" required>
          <UiKitInput
            v-model="subtitle"
            :placeholder="t('subtitle.placeholder')"
          />
          <UiKitFormFieldErrors :errors="v$.review.subtitle.$errors" />
        </UiKitFormField>
      </div>

      <div class="col-12">
        <UiKitFormField class="mb-4" :label="t('text.label')" required>
          <UiKitWysiwyg v-model="text" :placeholder="t('text.placeholder')" />
          <UiKitFormFieldErrors :errors="v$.review.text.$errors" />
        </UiKitFormField>

        <UiKitFormField :label="t('date.label')" required>
          <UiKitDatePicker
            v-model="date"
            :placeholder="t('date.placeholder')"
            date-label=""
          />
          <UiKitFormFieldErrors :errors="v$.review.date.$errors" />
        </UiKitFormField>
      </div>
    </div>
  </div>
</template>

<script>
import UiKitUploadAny from '@admin/ui/admin/UiKitUploadAny.vue'
import UiKitDatePicker from '@admin/ui/admin/UiKitDatePicker.vue'
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitInput from '@admin/ui/admin/UiKitInput.vue'
import UiKitWysiwyg from '@admin/ui/admin/UiKitWysiwyg.vue'
import RatingStars from '@admin/components/admin/productManagement/product/content/items/reviews/RatingStars.vue'
import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from 'Helpers/i18n-validators'
import UiKitFormFieldErrors from '@admin/ui/admin/UiKitFormFieldErrors.vue'

export default defineComponent({
  name: 'FormEditProgram',

  components: {
    UiKitFormFieldErrors,
    UiKitUploadAny,
    UiKitDatePicker,
    UiKitFormField,
    UiKitInput,
    UiKitWysiwyg,
    RatingStars,
  },

  model: {
    prop: 'review',
    event: 'input',
  },

  props: {
    review: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      review: {
        authorName: {
          required,
        },

        date: {
          required,
        },

        rate: {
          required,
        },

        subtitle: {
          required,
        },

        text: {
          required,
        },
      },
    }
  },

  computed: {
    photo: {
      get() {
        return this.review.photo
      },

      set(value) {
        this.$emit('input', {
          key: 'photo',
          value,
        })
      },
    },

    authorName: {
      get() {
        return this.review.authorName
      },

      set(value) {
        this.$emit('input', {
          key: 'authorName',
          value,
        })
      },
    },

    subtitle: {
      get() {
        return this.review.subtitle
      },

      set(value) {
        this.$emit('input', {
          key: 'subtitle',
          value,
        })
      },
    },

    date: {
      get() {
        return this.review.date
      },

      set(value) {
        this.$emit('input', {
          key: 'date',
          value,
        })
      },
    },

    text: {
      get() {
        return this.review.text
      },

      set(value) {
        this.$emit('input', {
          key: 'text',
          value,
        })
      },
    },

    rate: {
      get() {
        return this.review.rate
      },

      set(value) {
        this.$emit('input', {
          key: 'rate',
          value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.form.${name}`,
      )
    },
  },
})
</script>
