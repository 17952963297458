import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class List extends ApiBase {
  /**
   * @param {number} testId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(testId, parameters = {}) {
    return this._GET(
      `/tests/${testId}/mark-settings?${UrlHelper.param(parameters)}`,
    )
  }
})()
