import TheStudentDemo from '@admin/pages/admin/student/TheStudentDemo.vue'

const routes = [
  {
    path: '/students',
    name: 'admin-students',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-students" */ '@admin/pages/admin/student/Students.vue'
      ),
  },
  {
    path: '/students-demo',
    name: 'admin-students-demo',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-students-demo" */ '@admin/pages/admin/student/StudentsDemo.vue'
      ),
  },
  {
    path: '/students-educont',
    name: 'admin-students-educont',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-students-educont" */ '@admin/pages/admin/student/StudentsEducont.vue'
      ),
  },
  {
    path: '/student/create',
    name: 'admin-the-student-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-student" */ '@admin/pages/admin/student/TheStudent.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/:studentId(\\d+)',
    component: () =>
      import(
        /* webpackChunkName: "admin-the-student" */ '@admin/pages/admin/student/TheStudent.vue'
      ),
    children: [
      {
        path: 'statement/:groupId(\\d+)',
        name: 'admin-student-the-statement',
        meta: {
          accessControlGroup: 'student',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-student-the-statement" */ '@admin/pages/admin/student/StudentTheStatement.vue'
          ),
      },
      {
        path: '',
        name: 'admin-the-student',
        meta: {
          accessControlGroup: 'student',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-student-statements" */ '@admin/pages/admin/student/StudentStatements.vue'
          ),
      },
    ],
  },
  {
    path: '/student-demo-create',
    name: 'admin-the-student-demo-create',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: TheStudentDemo,
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student-demo/:studentDemoId(\\d+)',
    name: 'admin-the-student-demo',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: TheStudentDemo,
  },
  {
    path: '/student-educont/:studentEducontId(\\d+)',
    name: 'admin-the-student-educont',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-student-educont" */ '@admin/pages/admin/student/TheStudentEducont.vue'
      ),
  },
  {
    path: '/student/group-distribution',
    name: 'admin-student-group-distribution',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "student-group-distribution" */ '@admin/pages/admin/student/StudentGroupDistribution.vue'
      ),
  },
  {
    path: '/student/transfer',
    name: 'admin-student-transfer',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-transfer" */ '@admin/pages/admin/student/StudentTransfer.vue'
      ),
  },
  {
    path: '/student/groups',
    name: 'admin-student-groups',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-groups" */ '@admin/pages/admin/student/StudentGroups.vue'
      ),
  },
  {
    path: '/student/group/create',
    name: 'admin-student-the-group-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-the-group" */ '@admin/pages/admin/student/StudentTheGroup.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/group/:groupId(\\d+)',
    name: 'admin-student-the-group',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-the-group" */ '@admin/pages/admin/student/StudentTheGroup.vue'
      ),
  },
  {
    path: '/student/streams',
    name: 'admin-student-streams',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-streams" */ '@admin/pages/admin/student/StudentStreams.vue'
      ),
  },
  {
    path: '/student/stream/create',
    name: 'admin-student-the-stream-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-the-stream" */ '@admin/pages/admin/student/StudentTheStream.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/stream/:streamId(\\d+)',
    name: 'admin-student-the-stream',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-student-the-stream" */ '@admin/pages/admin/student/StudentTheStream.vue'
      ),
  },
  {
    path: '/students/trash',
    name: 'admin-students-trash',
    meta: {
      accessControlGroup: 'student',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-students-trash" */ '@admin/pages/admin/student/StudentsTrash.vue'
      ),
  },
]

export default routes
