import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setNewsArticle(state, payload) {
    state.newsArticle = payload
  },

  setNewsArticleValue(state, { key, value }) {
    Vue.set(state.newsArticle, key, value)
  },

  addFile(state, file) {
    state.newsArticle.files.push(file)
  },

  addTag(state, tag) {
    state.newsArticle.tags = [
      ...state.newsArticle.tags,
      tag,
    ]
  },

  /**
   * @param {Object|undefined} state
   * @param {Object} tag
   */
  removeTag(state, tag) {
    state.newsArticle.tags = state.newsArticle.tags.filter(
      ({ name }) => name !== tag.name,
    )
  },

  removeFile(state, index) {
    state.newsArticle.files.splice(index, 1)
  },

  setOption(state, { path, value }) {
    Vue.set(state.options, path, value)
  },
}
