import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLastRequestConfirmLog(state, payload) {
    state.lastRequestConfirmLog = payload
  },

  setLogs(state, payload) {
    state.logs = payload
  },
}
