import { ApiAnalyticsLibraryDownload } from 'ApiRest/Api/Analytics/Library/Download'
import { ApiAnalyticsLibrary } from 'ApiRest/Api/Analytics/Library/List'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchLibrary({ commit }, parameters) {
    const { data } = await ApiAnalyticsLibrary.get(parameters)

    commit('setLibrary', data)
  },

  async downloadAnalyticsLibrary(context, parameters) {
    const response = await ApiAnalyticsLibraryDownload.get(parameters)

    DownloadHelper.downloadBlob(response)
  },
}
