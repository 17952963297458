import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setWiki(state, payload) {
    state.wiki = payload
  },

  setWikiWithChildren(state, payload) {
    state.wikiWithChildren = payload
  },

  setWikiWithoutChildren(state, payload) {
    state.wikiWithoutChildren = payload
  },

  setTheWiki(state, payload) {
    state.theWiki = payload
  },

  setTheWikiContent(state, payload) {
    state.theWikiContent = payload
  },

  replaceTheWikiContentItem(state, payload) {
    const newItem = {
      ...payload,
      disabled: true,
    }

    state.theWiki.wikiContent.splice(-1, 1, newItem)
  },

  setTheWikiValue(state, { key, value }) {
    Vue.set(state.theWiki, key, value)
  },

  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },
}
