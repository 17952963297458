export const showcaseOnlineLessonRoutes = [
  {
    path: '/showcase/online-lessons',
    name: 'admin-showcase-online-lessons',
    component: () =>
      import(
        /* webpackChunkName: "admin-showcase-online-lessons" */
        '@admin/pages/admin/showcase/onlineLesson/OnlineLessons.vue'
      ),
  },
  {
    path: '/showcase/online-lesson/create',
    name: 'admin-showcase-the-online-lesson-create',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-showcase-the-online-lesson" */
        '@admin/pages/admin/showcase/onlineLesson/TheOnlineLesson.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/showcase/online-lesson/:onlineLessonId(\\d+)',
    name: 'admin-showcase-the-online-lesson',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-showcase-the-online-lesson" */
        '@admin/pages/admin/showcase/onlineLesson/TheOnlineLesson.vue'
      ),
  },
]
