import { Pagination } from '@admin/helpers/admin/CompositePagination'
import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setAdvertisements(state, payload) {
    state.advertisements = payload
  },

  setAdvertisement(state, payload) {
    state.advertisement = payload
  },

  setOptions(state, { path, value }) {
    set(state.options, path, value)
  },

  setAdvertisementValue(state, { path, value }) {
    set(state.advertisement, path, value)
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    if (state.pagination !== null) {
      compositePagination.setRowsPerPage(state.pagination.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, value) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, value) {
    state.filters = value
  },
}
