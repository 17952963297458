const routes = [
  {
    path: '/credits',
    name: 'admin-credits',
    component: () =>
      import(
        /* webpackChunkName: "admin-credits" */ '@admin/pages/admin/credits/Credits.vue'
      ),
  },
]

export default routes
