import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setDepartments(state, { path, value }) {
    set(state.departments, path, value)
  },

  setAdditionalContacts(state, payload) {
    state.additionalContacts = payload
  },

  setTheDepartment(state, { path, value }) {
    const index = state.departments[path].findIndex(
      (department) => department.id === value.id,
    )

    Vue.set(state.departments[path], index, value)
  },

  setDefaultDepartment(state, departmentId) {
    state.departments.common.forEach((department) => {
      department.isDefault = department.id === departmentId
    })
  },

  addTheDepartment(state, payload) {
    state.departments.common.push(payload)
  },

  removeTheDepartment(state, departmentId) {
    const index = state.departments.common.findIndex(
      (department) => department.id === departmentId,
    )

    state.departments.common.splice(index, 1)
  },

  setOption(state, { path, value }) {
    Vue.set(state.options, path, value)
  },

  setOptionValue(state, { path, value }) {
    Vue.set(state.optionValues, path, value)
  },
}
