const routes = [
  {
    path: '/homeworks',
    name: 'admin-homeworks',
    meta: {
      accessControlGroup: 'homework',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-homeworks" */ '@admin/pages/admin/homework/Homeworks.vue'
      ),
  },
  {
    path: '/homework/:solutionId(\\d+)',
    name: 'admin-the-homework',
    meta: {
      accessControlGroup: 'homework',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-homework" */ '@admin/pages/admin/homework/TheHomework.vue'
      ),
  },
]

export default routes
