import { ApiTriggerSendingAnalytics } from 'ApiRest/Api/TriggerSending/Analytics'

export default {
  async fetchAnalytics({ rootState, commit }, filters = {}) {
    const { data, headers } = await ApiTriggerSendingAnalytics.get(
      rootState.admin.triggerSending.triggerSending.id,
      filters,
    )

    commit('setAnalytics', data)
    commit('setFilters', filters)
    commit('setPagination', headers)
  },
}
