import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class TheFamilyEducationStatementManualAttempt extends ApiBase {
  /**
   * @param {Object} parameters
   * @param {Object} data
   * @returns {Promise}
   */
  put(parameters, data = {}) {
    return this._PUT(
      `/family-statement/manual-attempt?${UrlHelper.param(parameters)}`,
      data,
    )
  }
})()
