import { getFullName } from 'Helpers/fullName'

export default {
  fullName(state) {
    return getFullName(state.profile)
  },

  getActiveModulesById(state) {
    return (idName) =>
      state.activeModules.find((activeModule) => activeModule.id === idName)
  },
}
