<template>
  <div id="app">
    <transition-group name="fade" mode="out-in">
      <LoaderFullScreen v-if="isLoading" key="loader" />

      <template v-else>
        <Theme key="theme" />

        <router-view v-if="isTextsLoaded" key="layout" />
      </template>
    </transition-group>
  </div>
</template>

<script>
import Theme from '@admin/components/admin/Theme.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import FontFaceObserver from 'fontfaceobserver'
import { initTrackers } from 'Helpers/trackers/init'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    Theme,
    LoaderFullScreen,
  },

  metaInfo: {
    titleTemplate(titleChunk) {
      return titleChunk ? `${titleChunk} | IBLS` : 'IBLS'
    },
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  computed: {
    ...mapState({
      isLoadingTheme: (state) => state.admin.theme.isLoading,
    }),

    ...mapState('admin/navigation', [
      'textsLoaded',
    ]),

    isLoading() {
      return this.isLoadingTheme || this.isLoadingFonts
    },

    isTextsLoaded() {
      return this.textsLoaded
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('admin/theme', [
      'getTheme',
    ]),

    ...mapActions('admin/navigation', [
      'fetchProfile',
    ]),

    async init() {
      await Promise.all([
        this.getTheme(),
        this.fetchProfile(),
        this.initFonts(),
        initTrackers(),
      ])
    },

    async initFonts() {
      await document.fonts.ready

      const fontawesome = new FontFaceObserver('Font Awesome 5 Free')

      await fontawesome.load()

      this.isLoadingFonts = false
    },
  },
}
</script>

<style lang="scss">
@import '~@admin/app.scss';
</style>
