import { compareDesc, parseISO } from 'date-fns'
import dateFormatHelper from 'Helpers/dateFormatHelper'

const COORDINATOR_COUNT_MAX = 5

/**
 * @param {Object} a
 * @param {Object} b
 * @returns {number}
 */
function _sortByDate(a, b) {
  // TODO: remove deprecated method
  return compareDesc(
    parseISO(dateFormatHelper.toIso(a.createdAt)),
    parseISO(dateFormatHelper.toIso(b.createdAt)),
  )
}

export default {
  coordinatorCount(state) {
    return state.partner.coordinator?.length ?? 0
  },

  isCoordinatorCountMax(state, getters) {
    return getters.coordinatorCount < COORDINATOR_COUNT_MAX
  },

  commentsSortedByDate(state) {
    return [
      ...state.comments,
    ].sort(_sortByDate)
  },
}
