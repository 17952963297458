import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setHomeworks(state, value) {
    state.homeworks = value
  },

  setTheHomework(state, value) {
    state.homework = value
  },

  setHomeworkValue(state, { key, value }) {
    Vue.set(state.homework, key, value)
  },

  setOptions(state, { key, value }) {
    Vue.set(state.options, key, value)
  },
}
