import { ApiAnalyticsOnlineLessonChart } from 'ApiRest/Api/Analytics/OnlineLesson/Chart'
import { ApiAnalyticsOnlineLessonCommon } from 'ApiRest/Api/Analytics/OnlineLesson/Common'
import { ApiAnalyticsOnlineLessonData } from 'ApiRest/Api/Analytics/OnlineLesson/Data'
import { ApiAnalyticsOnlineLessonDetail } from 'ApiRest/Api/Analytics/OnlineLesson/Detail'
import { ApiAnalyticsOnlineLessonJournal } from 'ApiRest/Api/Analytics/OnlineLesson/Journal'
import { ApiAnalyticsOnlineLessonUsers } from 'ApiRest/Api/Analytics/OnlineLesson/Users'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchChart({ commit, state }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonChart.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setChart', data)
  },

  async fetchCommon({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonCommon.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setCommon', data)
  },

  async fetchData({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonData.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setData', data)

    // const data = [
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 3,
    //      name: '3',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //      {
    //        id: 2,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //      {
    //        id: 3,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 20,
    //    count: 200,
    //    lessonsCount: 11,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 4,
    //      name: '4',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 22,
    //    count: 120,
    //    lessonsCount: 51,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 5,
    //      name: '5',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 52,
    //    count: 230,
    //    lessonsCount: 11,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 3,
    //      name: '3',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 92,
    //    count: 290,
    //    lessonsCount: 19,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 1,
    //      name: 'Наши Пенаты',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 4,
    //      name: '4',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 25,
    //    count: 205,
    //    lessonsCount: 15,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 2,
    //      name: 'Родитель-Учитель',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 5,
    //      name: '5',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 2,
    //    count: 20,
    //    lessonsCount: 1,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 2,
    //      name: 'Родитель-Учитель',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 3,
    //      name: '3',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 2,
    //    count: 20,
    //    lessonsCount: 1,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 2,
    //      name: 'Родитель-Учитель',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 4,
    //      name: '4',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 2,
    //    count: 20,
    //    lessonsCount: 1,
    //  },
    //  {
    //    dateStart: '2023-01-01',
    //    dateEnd: '2023-08-31',
    //    school: {
    //      id: 2,
    //      name: 'Родитель-Учитель',
    //    },
    //    grade: {
    //      id: 1,
    //      name: '1',
    //    },
    //    studentSchool: {
    //      id: 1,
    //      name: 'Олимп Плюс',
    //    },
    //    studentGrade: {
    //      id: 5,
    //      name: '5',
    //    },
    //    subject: {
    //      id: 1,
    //      name: 'Английский язык',
    //    },
    //    teachers: [
    //      {
    //        id: 93912,
    //        name: 'Шагал Марк Иосифович',
    //      },
    //    ],
    //    uniqueUsers: 2,
    //    count: 20,
    //    lessonsCount: 1,
    //  },
    // ]
    //
    // commit('setData', data)
  },

  async fetchDetail({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonDetail.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setDetail', data)
  },

  async fetchUsers({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonUsers.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setUsers', data)
  },

  async downloadJournal(context, filters) {
    const response = await ApiAnalyticsOnlineLessonJournal.get(filters)

    DownloadHelper.downloadBlob(response)
  },
}
