import { i18n } from '@/i18n/setup'
import { getLocaleMessages } from '@frontend/helpers/getLocaleMessages'
import ApiOption from 'ApiRest/Api/Option'
import { getCookie } from 'Helpers/cookie'

export default {
  async fetchLangOptions({ commit }) {
    const { data } = await ApiOption.getLanguages()

    commit('setLangOptions', data)
  },

  setLangDefault({ commit }) {
    let lang = getCookie('lang') || 'ru'

    if (window.location.host.includes('english-demo.iblschool.ru')) {
      lang = 'en'
    }

    commit('setLang', lang)
  },

  resetLangOfHtml(state) {
    document.querySelector('html').setAttribute('lang', state.lang)
  },

  resetLocale({ getters, dispatch }) {
    i18n.locale = getters.localeName
    i18n.fallbackLocale = getters.localesFallback

    dispatch('resetLangOfHtml')
  },

  async changeLocale({ state, getters, commit, dispatch }) {
    if (!getters.isLoadedLocale) {
      for await (const locale of getters.localesFallback) {
        const messages = await getLocaleMessages({
          userType: state.userType,
          institutionType: state.institutionType,
          lang: locale,
        })

        i18n.setLocaleMessage(locale, messages)

        commit('setLocalesLoaded', [
          ...state.localesLoaded,
          locale,
        ])
      }

      if (state.institutionId) {
        const messages = await getLocaleMessages({
          userType: state.userType,
          institutionType: state.institutionType,
          institutionId: state.institutionId,
          lang: getters.localeName,
        })

        i18n.setLocaleMessage(getters.localeName, messages)

        commit('setLocalesLoaded', [
          ...state.localesLoaded,
          getters.localeName,
        ])
      }
    }

    dispatch('resetLocale')
  },
}
