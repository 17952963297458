const routes = [
  {
    path: '/sendings',
    name: 'admin-sendings',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-sendings" */ '@admin/pages/admin/sending/Sendings.vue'
      ),
  },
  {
    path: '/sending/create',
    name: 'admin-the-sending-create',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-sending" */ '@admin/pages/admin/sending/TheSending.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/sending/:sendingId(\\d+)',
    name: 'admin-the-sending',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-sending" */ '@admin/pages/admin/sending/TheSending.vue'
      ),
  },
]

export default routes
