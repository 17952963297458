import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiCourseManagementProductList = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params) {
    const urlParams = UrlHelper.param(params)
    const url = `/courses/product/list?${urlParams}`

    return this._GET(url)
  }
})()
