const routes = [
  {
    path: '/rating',
    name: 'admin-rating',
    component: () =>
      import(
        /* webpackChunkName: "admin-rating" */ '@admin/pages/admin/rating/Rating.vue'
      ),
  },
]

export default routes
