import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Аналитика - Аналитика online-уроков
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setAbilityByModuleAnalyticsOnlineLesson = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  /**
   * @type {{
   *   settings: {
   *     'conference-type': Object
   *   }
   * }|undefined}
   */

  if (getActiveModule(activeModules, 'analytics-online-lesson') !== undefined) {
    const routeNames = routes.get('analytics-online-lessons')

    if (permission === 'menu_analytics_online_lesson') {
      helpers.allowReadMenuItemForRoutes(routeNames)

      return true
    }

    if (permission === 'analytics_online_lesson') {
      helpers.allowReadRoutes(routeNames)

      return true
    }

    if (permission === userActions.ANALYTICS_ONLINE_LESSON_DO) {
      helpers.allowReadRoutes(routeNames)
      helpers.allowActionOnRoutes(userActions.ANALYTICS_ONLINE_LESSON_DO, [
        'admin-settings-the-school',
        'admin-analytics-online-lessons',
      ])

      return true
    }

    if (permission === userActions.ONLINE_LESSON_DETAIL_ANALYTICS) {
      helpers.allowActionOnRoutes(userActions.ONLINE_LESSON_DETAIL_ANALYTICS, [
        'admin-the-online-lesson',
        'admin-showcase-the-online-lesson',
      ])

      return true
    }
  }

  return false
}
