import Constants from 'Constants'

export default {
  isClientCitizenshipRussia(state) {
    return (
      state.order.customer.citizenship.id ===
      Constants.citizenship.RUSSIAN_FEDERATION
    )
  },

  isClientCitizenshipOtherCountry(state) {
    return (
      state.order.customer.citizenship.id ===
      Constants.citizenship.OTHER_COUNTRY
    )
  },

  hasClientDocuments(state) {
    return state.order.clientDocuments.length > 0
  },

  consumer(state) {
    return state.order?.items?.map((product) => product.consumer)[0]
  },
}
