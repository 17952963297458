import { RESET_STATE } from 'Helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCategories(state, payload) {
    state.categories = payload
  },

  setCategory(state, payload) {
    state.category = payload
  },

  setCategoryValue(state, { path, value }) {
    set(state.category, path, value)
  },
}
