export const supportRoutes = [
  {
    path: '/support',
    name: 'admin-support',
    meta: {
      accessControlGroup: 'admin-support-questions',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-support" */ '@admin/pages/admin/support/Support.vue'
      ),
    children: [
      {
        path: 'questions',
        name: 'admin-support-questions',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-support-questions" */ '@admin/pages/admin/support/Questions.vue'
          ),
      },
      {
        path: 'questions/:questionId(\\d+)',
        name: 'admin-support-the-question',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-question" */ '@admin/pages/admin/support/TheQuestion.vue'
          ),
      },
      {
        path: 'questions/create',
        name: 'admin-support-the-question-create',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-question-create" */ '@admin/pages/admin/support/TheQuestionCreate.vue'
          ),
      },

      {
        path: 'ideas',
        name: 'admin-support-ideas',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-ideas" */ '@admin/pages/admin/support/Ideas.vue'
          ),
      },
      {
        path: 'ideas/:ideaId(\\d+)',
        name: 'admin-support-the-idea',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-idea" */ '@admin/pages/admin/support/TheIdea.vue'
          ),
      },

      {
        path: 'faq',
        name: 'admin-support-faq',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-faq" */ '@admin/pages/admin/support/FAQ.vue'
          ),
      },
      {
        path: 'faq/topic/create',
        name: 'admin-support-the-faq-topic-create',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-faq-topic" */ '@admin/pages/admin/support/TheFAQTopic.vue'
          ),
        props: {
          isCreate: true,
        },
      },
      {
        path: 'faq/topic/:topicId(\\d+)',
        name: 'admin-support-the-faq-topic',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-faq-topic" */ '@admin/pages/admin/support/TheFAQTopic.vue'
          ),
      },
      {
        path: 'faq/topic/:topicId(\\d+)/item/create',
        name: 'admin-support-the-faq-question-create',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-faq-question" */ '@admin/pages/admin/support/TheFAQQuestion.vue'
          ),
        props: {
          isCreate: true,
        },
      },
      {
        path: 'faq/topic/:topicId(\\d+)/item/:itemId(\\d+)',
        name: 'admin-support-the-faq-question',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-the-faq-question" */ '@admin/pages/admin/support/TheFAQQuestion.vue'
          ),
      },

      {
        path: 'settings',
        name: 'admin-support-question-settings',
        component: () =>
          import(
            /* webpackChunkName: "admin-support-question-settings" */ '@admin/pages/admin/support/QuestionSettings.vue'
          ),
      },
    ],
  },
]
