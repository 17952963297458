import { testAvailabilityTime } from '@admin/helpers/admin/TestAvailabilityTime'
import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setServices(state, payload) {
    state.services = payload
  },

  setSubjectsSettings(state, { settings, key }) {
    state[key].subjects = settings.subjectSettings.map((setting) => ({
      ...setting,
      isChecked: false,
    }))
  },

  setRequestId(state, payload) {
    state.requestId = payload
  },

  setRequest(state, payload) {
    state.request = payload
  },

  changeSetting(state, { settingIndex, value, key }) {
    Vue.set(state[key].subjects[settingIndex], 'isChecked', value)
  },

  setService(state, { value, key }) {
    state[key].service = value
  },

  checkAllSubjects(state, { key, value }) {
    state[key].subjects.forEach((subject) => {
      Vue.set(subject, 'isChecked', value)
    })
  },

  uncheckAllSubjects(state, key) {
    state[key].subjects.forEach((subject) => {
      Vue.set(subject, 'isChecked', false)
    })
  },

  setCheckedEach(state, { key, value }) {
    state[key] = value
  },

  setTestAvailabilityTime(state, payload) {
    state.testAvailabilityTime = payload
  },

  unsetTestAvailabilityTime(state) {
    state.testAvailabilityTime = testAvailabilityTime
  },

  unsetService(state) {
    state.extramuralDiagnostics.service = null
    // TODO: redmine.ibls.tech/issues/6129 скрыт блок очной диагностики
    // state.fullTimeDiagnostics.service = null
  },
}
